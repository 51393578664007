<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3351 1.99999H12.6684V0.666656H11.3351V1.99999H4.66842V0.666656H3.33508V1.99999H2.66842C1.93508 1.99999 1.33508 2.59999 1.33508 3.33332V14C1.33508 14.7333 1.93508 15.3333 2.66842 15.3333H13.3351C14.0684 15.3333 14.6684 14.7333 14.6684 14V3.33332C14.6684 2.59999 14.0684 1.99999 13.3351 1.99999ZM13.3351 14H2.66842V5.33332H13.3351V14Z" />
  </svg>
</template>

<script>
export default {
  name: 'calendarIcon'
}
</script>
