<template>
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_716_20737)">
      <path opacity="0.15" d="M0.501709 8C0.501709 3.85786 3.85957 0.5 8.00171 0.5H24.0017C28.1438 0.5 31.5017 3.85786 31.5017 8V24C31.5017 28.1421 28.1438 31.5 24.0017 31.5H8.00171C3.85957 31.5 0.501709 28.1421 0.501709 24V8Z" stroke="#C90D46"/>
      <path d="M26.6684 14.6667H10.4417L17.8951 7.21334L16.0017 5.33334L5.33508 16L16.0017 26.6667L17.8817 24.7867L10.4417 17.3333H26.6684V14.6667Z" fill="#C90D46"/>
    </g>
    <defs>
      <clipPath id="clip0_716_20737">
        <rect width="32" height="32" fill="white" transform="translate(0.00170898)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'arrowBackIcon'
}
</script>
