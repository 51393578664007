<template>
  <div :class="['v-input', 'v-input__field', {'v-input__error': error}, {'v-input__disabled': disabled}]" @click="focusInput">
    <input
        :maxlength=maxlength
        :id=className
        :ref=className
        :class="['v-input__input', {'v-input__required': modelValue}]"
        :value="modelValue"
        v-on="validationListeners"
        type="text"
        v-maska="mask"
        :disabled="disabled"
        :readonly="readonly"
        autocomplete="off">
    <label :for=className class="v-input__label">
      {{placeholder}}
    </label>
  </div>
</template>
<script>
import { maska } from 'maska'

export default {
  name: 'vInput',
  props: ['modelValue', 'placeholder', 'className', 'error', 'handleChange', 'maxlength', 'mask', 'disabled', 'readonly'],
  directives: { maska },
  methods: {
    focusInput(e) {
      e.preventDefault()
      this.$refs[this.className].focus()
    }
  },
  computed: {
    validationListeners () {
      // Если поле валидно или фокус не был потерян, то
      // делаем ленивую проверку инпута
      if (!this.error) {
        return {
          blur: this.handleChange,
          change: this.handleChange,
          // отключение `shouldValidate` для отключения проверки при событии input
          input: e => this.handleChange(e, false)
        }
      }
      // иначе делаем агрессивную проверку
      return {
        blur: this.handleChange,
        change: this.handleChange,
        input: this.handleChange
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .v-input {
   width: inherit;
   cursor: text;
   background-color: $color-white;
   &__field {
     position: relative;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     width: inherit;
     padding: 7px 20px;
     border: 1px solid $color-border-grey;
     box-sizing: border-box;
     border-radius: 16px;
     transition: 0.2s;
     margin-bottom: 24px;
   }
   &__input {
     transition: 0.2s;
     height: 17px;
     box-sizing: border-box;
     &:focus {
       margin-top: 16px;
     }

     &:disabled {
       background-color: #ffffff;
       color: rgb(170, 170, 170);

       div {
         user-select: none;
       }
     }

   }
   &__disabled {
     background-color: $color-white;
     color: rgb(170, 170, 170)
     //background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
     //color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
   }
   &__required {
     margin-top: 16px;
     margin-bottom: 0;
   }
   &__required + label {
     font-size: 12px;
     line-height: 21px;
     transform: translate(2px,-2px);
   }

   &__error {
     border: 1px solid $color-red
   }
   &__label {
     top: 5px;
     cursor: text;
     font-size: 16px;
     line-height: 21px;
     position: absolute;
     transition: 0.2s;
     color: $color-grey;
   }

   &__input:focus + label {
     font-size: 12px;
     line-height: 21px;
     transform: translate(2px,-2px);
   }
   &__input {
     border: none;
     &:focus {
      outline: none;
     }
     &::placeholder {
       color: $color-grey;
     }
   }
 }
</style>
