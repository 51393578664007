<template>
  <nav class="v-navigation">
    <ul class="v-navigation__list">
      <router-link v-for="nav in navs" :key="nav.id" :to=nav.link custom
                   v-slot="{ href=nav.link, navigate, isActive, isExactActive }">
        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active', 'v-navigation__item']">
          <a :href="href" @click="navigate" class="v-navigation__link">
            <span class="tabs__item--text">{{ nav.text }}</span>
          </a>
        </li>
      </router-link>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'vNavigation',
  props: ['navs']
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';
.v-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    list-style-type: none;
  }

  &__link {
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    margin-right: 40px;
    border-bottom: 1px solid transparent;
    padding-bottom: 8.5px;
    text-decoration: none;
    color: $color-light-white;
    user-select: none;
  }
  .router-link-active a{
    opacity: 0.3;
  }
}
</style>
