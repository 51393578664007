<template>
  <div class="dp" v-click-outside="clickOutside">
    <div class="input" :class="{ 'input_disabled': disabled }" @click="isShowCalendar = true">
      <v-input
        :placeholder="placeholder"
        :modelValue="date ? dateFormatter.format(date) : ''"
        :error="error"
        :className="className"
        :disabled="disabled"
        @change="inputChangeHandler"
        :mask="dateMask"
        :handleChange="() => {}" />
      <CalendarIcon class="input__icon input__icon-calendar" :class="{ disabled }" />
      <ApproveNoIcon v-if="date" class="input__icon input__icon-approve-no" @click.stop="clearInput" />
    </div>
    <div v-if="isShowCalendar" class="calendar">
      <div class="calendar__header">
        <span class="calendar__month">{{ ALL_MONTHS[month] }}</span>
        &nbsp;
        <span class="calendar__year">{{ year }}</span>
        <ArrowBackIcon class="calendar__arrow" @click="leftButtonClick" />
        <ArrowBackIcon class="calendar__arrow calendar__arrow-right" @click="rightButtonClick" />
      </div>
      <ul class="calendar__week-days">
        <li v-for="day in WEEK_DAYS" :key="day">{{ day }}</li>
      </ul>
      <ul class="calendar__row" v-for="(row, index) in weeks" :key="index">
        <li
          class="calendar__cell"
          :class="{'calendar__cell_hide': !cell.isCurMonth, 'calendar__cell_current': cell.isCurDay, 'calendar__cell_active': cell.time === date}"
          v-for="cell in row"
          :key="cell.id"
          :data-time="cell.time"
          @click="cellClickHandler(cell.time)"
        >{{ cell.number }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'

import ArrowBackIcon from '@/components/icons/arrow-back-icon.vue'
import ApproveNoIcon from '@/components/icons/approve-no-icon.vue'
import CalendarIcon from '@/components/icons/calendar-icon.vue'

import dateFormatter from '@/utils/date-formatter'
import { dateMask } from '@/utils/inputMasks'
import dateToTimestamp from '@/utils/dateToTimestamp'

const WEEK_DAYS = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
const ALL_MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

export default {
  name: 'vCalendar',
  components: { ArrowBackIcon, ApproveNoIcon, CalendarIcon },
  props: ['date', 'error', 'placeholder', 'className', 'disabled', 'handleChange'],
  emits: ['update:date'],
  setup(props, { emit }) {
    const isShowCalendar = ref(false)

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()

    let counter = 0
    const inputValue = ref(null)

    const activeDate = computed(() => props.date ? new Date(props.date) : new Date())
    const today = ref(activeDate.value.getDate())
    const year = ref(activeDate.value.getFullYear())
    const month = ref(activeDate.value.getMonth())
    const firstWeekDay = computed(() => new Date(year.value, month.value, 1).getDay() || 7)
    const numberDaysInMonth = computed(() => new Date(year.value, month.value + 1, 0).getDate())
    const numberWeeks = computed(() => Math.ceil((numberDaysInMonth.value + firstWeekDay.value - 1) / WEEK_DAYS.length))

    const monthDays = computed(() => {
      const list = []
      for (let i = firstWeekDay.value - 1; i < numberDaysInMonth.value + firstWeekDay.value - 1; i += 1) {
        list[i] = i - firstWeekDay.value + 2
      }
      return list
    })

    const weeks = computed(() => {
      const list = []
      for (let i = 0; i < numberWeeks.value; i += 1) {
        const week = []
        for (let j = 0; j < WEEK_DAYS.length; j += 1) {
          if ((today.value + firstWeekDay.value - 2 === counter) && (year.value === currentYear) && (month.value === currentMonth)) {
            week.push({
              number: monthDays.value[counter], isCurDay: true, isCurMonth: true, id: counter, year: year.value, month: month.value, time: +new Date(year.value, month.value, monthDays.value[counter])
            })
          } else if (monthDays.value[counter]) {
            week.push({
              number: monthDays.value[counter], isCurDay: false, isCurMonth: true, id: counter, year: year.value, month: month.value, time: +new Date(year.value, month.value, monthDays.value[counter])
            })
          } else if (!monthDays.value[counter] && monthDays.value.length > counter) {
            week.push({
              number: 99, isCurDay: false, isCurMonth: false, id: counter, year: year.value, month: month.value, time: +new Date(year.value, month.value, monthDays.value[counter])
            })
          } else if (!monthDays.value[counter]) {
            week.push({
              number: 99, isCurDay: false, isCurMonth: false, id: counter, year: year.value, month: month.value, time: +new Date(year.value, month.value, monthDays.value[counter])
            })
          }
          counter += 1
        }
        list.push(week)
      }
      counter = 0
      return list
    })

    const clickOutside = () => {
      isShowCalendar.value = false
    }

    const leftButtonClick = () => {
      if (month.value === 0) {
        month.value = 11
        year.value = year.value - 1
      } else {
        month.value = month.value - 1
      }
    }

    const rightButtonClick = () => {
      if (month.value === 11) {
        month.value = 0
        year.value = year.value + 1
      } else {
        month.value = month.value + 1
      }
    }

    const keydownHandler = event => {
      if (event.key === 'ArrowRight') {
        rightButtonClick()
      } else if (event.key === 'ArrowLeft') {
        leftButtonClick()
      } else if (event.key === 'Escape') {
        isShowCalendar.value = false
      } else if (event.key === 'Enter') {
        event.preventDefault()
        inputChangeHandler(event)
      }
    }

    const cellClickHandler = timestamp => {
      emit('update:date', timestamp)
      isShowCalendar.value = false
      inputValue.value = timestamp
    }

    const inputChangeHandler = event => {
      const [day, month, year] = event.target.value.split('.')
      cellClickHandler(+new Date(dateToTimestamp(`${day}.${month}.20${year}`)))
    }

    const clearInput = () => {
      emit('update:date', null)
      props.handleChange()
    }

    watch(activeDate, () => {
      today.value = activeDate.value.getDate()
      year.value = activeDate.value.getFullYear()
      month.value = activeDate.value.getMonth()
    })

    watch(isShowCalendar, () => {
      if (isShowCalendar.value) {
        document.addEventListener('keydown', keydownHandler)
      } else {
        props.handleChange(inputValue.value)
        document.removeEventListener('keydown', keydownHandler)
      }
    })

    return {
      dateMask, dateFormatter, isShowCalendar, WEEK_DAYS, ALL_MONTHS, weeks, month, year,
      leftButtonClick, rightButtonClick, clickOutside, cellClickHandler, inputChangeHandler, clearInput
    }
  }
}
</script>

<style lang="scss" scoped>
.dp {
  position: relative;

  .input {
    &:not(.input_disabled):hover .input__icon-approve-no {
      display: block;
    }

    &_disabled {
      pointer-events: none;
    }

    &__icon {
      position: absolute;
      line-height: 1;
      top: 49%;
      right: 21px;
      transform: translateY(-50%);
      fill: $color-red;

      &.disabled {
        fill: $color-grey;
      }

      &-approve-no {
        display: none;
        width: 22px;
        cursor: pointer;
        margin-right: -2px;
      }
    }
  }

  .calendar {
    position: absolute;
    background-color: $color-white;
    box-shadow: 0px 4px 8px rgba(23, 28, 32, 0.2);
    border-radius: 16px;
    padding: 20px 24px;
    right: 0;
    margin-top: 10px;
    user-select: none;
    z-index: 100;

    &__header {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
    }

    &__year {
      flex-grow: 1;
    }

    &__arrow {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    &__arrow-right {
      margin-left: 6px;
      transform: rotate(180deg);
    }

    &__week-days {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 17px;
      color: $color-grey;
      margin: 15px 0;
    }

    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__cell {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
      min-height: 24px;
      margin: 5.5px 3.5px;
      border-radius: 100%;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;

      &:hover {
        color: $color-white;
        background-color: $color-red;
      }

      &_hide {
        opacity: 0;
        pointer-events: none;
      }

      &_current {
        font-weight: 700;
        color: $color-red;
      }

      &_active {
        color: $color-white;
        background-color: $color-red;
      }
    }
  }
}
</style>
