<template>
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.085 16.3333H17.1634L16.8367 16.0183C17.98 14.6883 18.6684 12.9617 18.6684 11.0833C18.6684 6.895 15.2734 3.5 11.085 3.5C6.89671 3.5 3.50171 6.895 3.50171 11.0833C3.50171 15.2717 6.89671 18.6667 11.085 18.6667C12.9634 18.6667 14.69 17.9783 16.02 16.835L16.335 17.1617V18.0833L22.1684 23.905L23.9067 22.1667L18.085 16.3333ZM11.085 16.3333C8.18004 16.3333 5.83504 13.9883 5.83504 11.0833C5.83504 8.17833 8.18004 5.83333 11.085 5.83333C13.99 5.83333 16.335 8.17833 16.335 11.0833C16.335 13.9883 13.99 16.3333 11.085 16.3333Z" fill="#C90D46"/>
  </svg>
</template>

<script>
export default {
  name: 'searchIcon'
}
</script>
