<template>
  <div class="v-title">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'vTitle'
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/variables";
 .v-title {
   font-size: 34px;
   line-height: 41px;
   font-family: "Proxima Bold", sans-serif;
   font-weight: bold;
   color: $color-black;
 }
</style>
