import axios from 'axios'

const defaultState = {
  orders: [],
  order: {},
  statusFilter: [],
  years: [],
  pages: {
    total: 0,
    limit: 10,
    limitList: [10, 20, 30],
    page: 1
  },
  isLoading: false
}

export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setPages(state, pages) {
      state.pages = {
        ...state.pages,
        ...pages
      }
    },
    setYears(state, years) {
      state.years = years
    },
    setOrders(state, years) {
      state.orders = years
    },
    setOrder(state, order) {
      state.order = order
    },
    setLoading(state, bool) {
      state.isLoading = bool
    }
  },
  actions: {
    async getYearsList({ commit }) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/orders/available-years-to-search')
        commit('setLoading', false)
        commit('setYears', data)
        return Promise.resolve()
      } catch (error) {
        console.log('getYearsList', error)
        return Promise.reject(error.response.data.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async getOrders({ commit }, payload) {
      commit('setLoading', true)
      const hasStatus = !!payload.status.length
      // const hasStatus = payload.paid || payload.notPaid || payload.overpaid || payload.partiallyPaid || payload.awaitingPayment || payload.accepted || payload.delivered || payload.completed
      const params = {
        page: payload?.page ? parseInt(payload?.page, 10) : defaultState.pages.page,
        limit: payload?.limit ? parseInt(payload?.limit, 10) : defaultState.pages.limit,
        year: payload?.years ? payload.years.split(',') : undefined,
        // если статусов нет, то значит они все тру, имитация пустого массива равно полному массиву
        paid: hasStatus ? !!payload.status.includes('paid') : true,
        notPaid: hasStatus ? !!payload.status.includes('notPaid') : true,
        overpaid: hasStatus ? !!payload.status.includes('overpaid') : true,
        partiallyPaid: hasStatus ? !!payload.status.includes('partiallyPaid') : true,
        awaitingPayment: hasStatus ? !!payload.status.includes('awaitingPayment') : true,
        accepted: hasStatus ? !!payload.status.includes('accepted') : true,
        delivered: hasStatus ? !!payload.status.includes('delivered') : true,
        completed: hasStatus ? !!payload.status.includes('completed') : true
      }
      // Чтобы метод на беке нормально отрабатывал. нужно удалить позиции с false
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (params[key] === false) {
            delete params[key]
          }
        }
      }
      try {
        const { data } = await axios.get('/api/orders', { params })
        commit('setLoading', false)
        commit('setOrders', data.items)
        commit('setPages', {
          total: parseInt(data.paging.totalElements, 10),
          page: parseInt(data.paging.current, 10),
          limit: params.limit
        })
        return Promise.resolve()
      } catch (error) {
        console.log('getOrders', error)
        return Promise.reject(error.response.data.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async getOrderById({ commit }, id) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/order/${id}`)
        commit('setOrder', data)
      } catch (error) {
        console.log('getOrderById', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async approve({ dispatch }, { id, comment, status }) {
      try {
        const response = await axios.post(`/api/order/coordination/${id}/${status}`, { comment })
        await dispatch('getOrderById', id)
        return response
      } catch (error) {
        return error.response
      }
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    getPages(state) {
      return state.pages
    },
    getYears(state) {
      return state.years
    },
    getOrders(state) {
      return state.orders
    },
    getOrder(state) {
      return state.order
    }
  }
}
