import axios from 'axios'

const defaultState = {
  admins: [],
  admin: {},
  pages: {
    total: 0,
    limit: 10,
    limitList: [10, 20, 30],
    page: 1
  },
  isLoading: false
}
export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setAdmins(state, admins) {
      state.admins = admins
    },
    setAdmin(state, admin) {
      state.admin = admin
    },
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setTotal(state, total) {
      state.pages.total = total
    },
    setCurrent(state, current) {
      state.pages.page = current
    },
    setLimit(state, limit) {
      state.limit = limit
    },
    setPages(state, pages) {
      state.pages = {
        ...state.pages,
        ...pages
      }
    }
  },
  actions: {
    async loadAdmins({ commit }, payload) {
      commit('setLoading', true)
      try {
        const params = {
          page: payload?.page ? parseInt(payload?.page, 10) : defaultState.pages.page,
          limit: payload?.limit ? parseInt(payload?.limit, 10) : defaultState.pages.limit
        }
        const { data } = await axios.get('/api/administrator', {
          params: params
        })
        commit('setAdmins', data.items)
        commit('setPages', {
          total: parseInt(data.paging.totalElements, 10),
          page: parseInt(data.paging.current, 10),
          limit: params.limit
        })
        commit('setLoading', false)
      } catch (error) {
        console.log('loadAdmins', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadResponsibles({ commit }) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/administrator-responsibles')
        commit('setAdmins', data)
        commit('setLoading', false)
      } catch (error) {
        console.log('loadResponsibles', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadAdminById({ commit, state, getters }, id) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/administrator/${id}`)
        commit('setAdmin', data)
      } catch (error) {
        console.log('loadAdminById', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async createAdmin({ commit }, payload) {
      commit('setLoading', true)
      try {
        await axios.post('/api/administrator', payload)
        commit('setLoading', false)
        return Promise.resolve()
      } catch (error) {
        console.log('createAdmin', error)
        return Promise.reject(error.response.data.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async editAdmin({ commit }, payload) {
      const adminId = payload.id
      const editedAdmin = payload
      delete editedAdmin.id
      commit('setLoading', true)
      try {
        await axios.put(`/api/administrator/${adminId}`, editedAdmin)
        return Promise.resolve()
      } catch (error) {
        console.log('editAdmin', error)
        return Promise.reject(error.response.data.error)
      } finally {
        commit('setLoading', false)
      }
    },
    async blockAdmin({ commit }, id) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/administrator/${id}/block`)
      } catch (error) {
        console.log('editAdmin', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async deleteAdmin({ commit }, id) {
      commit('setLoading', true)
      try {
        await axios.delete(`/api/administrator/${id}`)
      } catch (error) {
        console.log('editAdmin', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async repeatEmail({ commit }, id) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/administrator-create-event/${id}`)
        commit('setLoading', false)
      } catch (error) {
        console.log('repeatEmail', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getAdmins(state) {
      return state.admins
    },
    getAdmin(state) {
      return state.admin
    },
    isLoading(state) {
      return state.isLoading
    },
    getPages(state) {
      return state.pages
    }
  }
}
