import axios from 'axios'

const defaultState = {
  catalog: [],
  categories: [],
  subCategories: [],
  vendors: [],
  language: 'ru',
  pages: {
    limit: 12,
    page: 1,
    limitList: [12, 24, 36],
    total: null
  },
  filters: {
    categoryId: '',
    subCategoryId: '',
    vendorId: ''
  },
  isLoading: false
}

export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setCatalog(state, catalog) {
      state.catalog = catalog
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setSubCategories(state, subCategories) {
      state.subCategories = subCategories
    },
    setVendors(state, vendors) {
      state.vendors = vendors
    },
    setTotal(state, total) {
      state.pages.total = total
    },
    setLimit(state, limit) {
      state.pages.limit = limit
    },
    setPage(state, page) {
      state.pages.page = page
    },
    setCategoryId(state, categoryId) {
      state.filters.categoryId = categoryId
    },
    setSubCategoryId(state, subCategoryId) {
      state.filters.subCategoryId = subCategoryId
    },
    setVendorId(state, vendorId) {
      state.filters.vendorId = vendorId
    }
  },
  actions: {
    async loadCatalog({ commit }, payload) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/catalog', {
          params: {
            page: +payload?.page || defaultState.pages.page,
            limit: +payload?.limit || defaultState.pages.limit,
            language: payload.language,
            filters: {
              categoryId: payload?.categoryId || '',
              subCategoryId: payload?.subCategoryId || '',
              vendorId: payload?.vendorId || ''
            }
          }
        })
        commit('setLimit', +payload?.limit || defaultState.pages.limit)
        commit('setPage', +payload?.page || defaultState.pages.page)
        commit('setCategoryId', payload?.categoryId || '')
        commit('setSubCategoryId', payload?.subCategoryId || '')
        commit('setVendorId', payload?.vendorId || '')
        commit('setCatalog', data.items)
        commit('setTotal', data.paging.totalElements)
      } catch (error) {
        console.log('setCatalog', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadCategories({ commit }, language) {
      // commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/categories', {
          params: { language }
        })
        commit('setCategories', data)
      } catch (error) {
        console.log('loadCategories', error)
      } finally {
        // commit('setLoading', false)
      }
    },
    async loadSubCategories({ commit }, payload) {
      // commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/subcategories', {
          params: { categoryId: payload.id, language: payload.language }
        })
        commit('setSubCategories', data)
      } catch (error) {
        console.log('loadSubCategories', error)
      } finally {
        // commit('setLoading', false)
      }
    },
    async loadVendors({ commit }, language) {
      // commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/vendors', {
          params: { language }
        })
        commit('setVendors', data)
      } catch (error) {
        console.log('loadVendors', error)
      } finally {
        // commit('setLoading', false)
      }
    }
  },
  getters: {
    getCatalog(state) {
      return state.catalog
    },
    getCategories(state) {
      return state.categories
    },
    getSubCategories(state) {
      return state.subCategories
    },
    getVendors(state) {
      return state.vendors
    },
    isLoading(state) {
      return state.isLoading
    },
    getPages(state) {
      return state.pages
    },
    getFilters(state) {
      return state.filters
    },
    getLanguage(state) {
      return state.language
    }
  }
}
