<template>
  <div class="picker-container">
    <div
      class="picker"
      :class="{ bigPicker: modelValue, disabled, error }"
      @click="pickerClickHandler"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div class="content" :class="{ active: modelValue }">
        <AttachIcon class="icon" :class="{ disabled }" />
        <p v-if="modelValue" class="fileName" :class="{ disabled }">{{ modelValue.name }}</p>
      </div>
      <p class="label" :class="{ active: modelValue }">{{ label }}</p>
      <input :ref="name" type="file" class="input" :accept="accept" @change="inputChangeHandler">
    </div>
    <p class="help">{{ description }}
      <a v-if="example" class="help__example" :href="example" target="_blank"> Пример</a>
    </p>
  </div>
</template>

<script>
import AttachIcon from '../icons/attach-icon.vue'

export default {
  name: 'vFilePicker',
  components: { AttachIcon },
  emits: ['update:modelValue', 'update:isChanged'],
  props: ['name', 'label', 'modelValue', 'accept', 'disabled', 'error', 'description', 'example'],
  methods: {
    pickerClickHandler() {
      this.$refs[this.name].click()
    },
    inputChangeHandler(event) {
      const file = event.target.files[0]
      if (file) this.loadFile(file)
    },
    loadFile(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
      }
      reader.onerror = function() {
      }
      this.$emit('update:modelValue', file)
      this.$emit('update:isChanged', true)
    },
    deleteFile() {
      this.$emit('update:modelValue', null)
      this.$emit('update:isChanged', true)
      this.$refs[this.name].value = null
    },
    dragover(event) {
      event.preventDefault()
      event.currentTarget.classList.add('dragover')
    },
    dragleave(event) {
      event.currentTarget.classList.remove('dragover')
    },
    drop(event) {
      event.preventDefault()
      event.currentTarget.classList.remove('dragover')
      this.loadFile(event.dataTransfer.files[0])
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.picker-container {
  width: 100%;
}

.picker {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 6px 20px;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 16px;
  transition: 0.5s;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    user-select: none;
  }

  &.error {
    border-color: $color-red;
  }

  &.bigPicker {
    height: 49px;
    align-items: flex-end;
  }
}

.icon {
  margin-right: 8px;
  margin-bottom: 3px;
  fill: $color-red;

  &.disabled {
    fill: $color-grey;
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20.8px;
  color: $color-grey;
  transition: 0.5s;

  &.active {
    position: absolute;
    font-size: 12px;
    line-height: 14.4px;
    top: 6px;
  }
}

.content {
  display: flex;

  &.active {
    width: 100%;
  }
}

.fileName {
  font-size: 16px;
  line-height: 21px;
  color: $color-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.disabled {
    color: $color-grey;
  }
}

.input {
  display: none;
}

.help {
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.2px;
  color: $color-grey;
  white-space: nowrap;
  margin-top: 4px;
  cursor: pointer;

  &__example {
    color: $color-red;
    text-decoration: none;
  }
}

.dragover {
  background-color: $color-background;
  transition: 0s;
}
</style>
