<template>
  <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50175 13.5L4.00175 9.99999L2.83508 11.1667L7.50175 15.8333L17.5017 5.83332L16.3351 4.66666L7.50175 13.5Z" />
  </svg>
</template>

<script>
export default {
  name: 'doneIcon'
}
</script>
