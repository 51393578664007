import { baseURL } from '@/utils/constants'

const setCookies = (cookies) => {
  const date = new Date()
  // Куки живут 10 sec
  date.setTime(date.getTime() + (10 * 1000))
  for (const [key, value] of cookies) {
    document.cookie = `${key}=${value}; domain=${baseURL}; expires=${date}; path=/`
  }
}

const parseCookie = () => {
  return document.cookie.split('; ').reduce((prev, current) => {
    const [name, ...value] = current.split('=')
    prev[name] = value.join('=')
    return prev
  }, {})
}

// куки удаляются сразу после редиректа,может быть менее 10 сек
const deleteCookies = () => {
  document.cookie = `accessToken=; domain=.${window.location.hostname.split('.').splice(1).join('.')}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `refreshToken=; domain=.${window.location.hostname.split('.').splice(1).join('.')}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = `domain=; domain=.${window.location.hostname.split('.').splice(1).join('.')}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
  document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
  document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
  document.cookie = 'domain=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
}

export { setCookies, parseCookie, deleteCookies }
