<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.00170898 12C0.00170898 5.37258 5.37429 0 12.0017 0C18.6291 0 24.0017 5.37258 24.0017 12C24.0017 18.6274 18.6291 24 12.0017 24C5.37429 24 0.00170898 18.6274 0.00170898 12Z" fill="#C90D46"/>
    <path d="M7.00171 7L17.0017 17" stroke="white" stroke-width="2" stroke-linecap="square"/>
    <path d="M7.00171 17L17.0017 7" stroke="white" stroke-width="2" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: 'approveNoIcon'
}
</script>
