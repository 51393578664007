import axios from 'axios'
import { jwtDecrypt } from '@/utils/jwtHelper'
import router from '@/router'
import { getFile } from '@/api/files'
import { deleteCookies } from '../../utils/cookie'

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const EXP_ACCESS_TOKEN = 'expAccessToken'
const EXP_REFRESH_TOKEN = 'expRefreshToken'
const USER = 'user'

export default {
  namespaced: true, state () {
    return {
      accessToken: localStorage.getItem(ACCESS_TOKEN),
      expAccessToken: localStorage.getItem(EXP_ACCESS_TOKEN) ? JSON.parse(localStorage.getItem(EXP_ACCESS_TOKEN)) : undefined,
      refreshToken: localStorage.getItem(REFRESH_TOKEN),
      expRefreshToken: localStorage.getItem(EXP_REFRESH_TOKEN) ? JSON.parse(localStorage.getItem(EXP_REFRESH_TOKEN)) : undefined,
      user: {
        id: localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)).id : undefined,
        name: JSON.parse(localStorage.getItem(USER))?.name ? JSON.parse(localStorage.getItem(USER)).name : 'Чичи Пипи',
        role: localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)).role : undefined,
        email: localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)).email : undefined
      },
      stepForm: 'email-form',
      isLoading: false,
      isLogoLoading: false,
      approvePolitic: false,
      counterCodeCheck: 0,
      errors: {
        emailError: null, codeError: null
      },
      timerFromServer: 0,
      logo: null
    }
  }, mutations: {
    setLoading (state, bool) {
      state.isLoading = bool
    }, setLogoLoading (state, bool) {
      state.isLogoLoading = bool
    }, setCounterCodeCheck (state, value) {
      state.counterCodeCheck = value
    }, setEmailError (state, error) {
      state.errors.emailError = error
    }, setCodeError (state, error) {
      state.errors.codeError = error
    }, setApprovePolitic (state, bool) {
      state.approvePolitic = bool
    }, setStepForm (state, formName) {
      state.stepForm = formName
    }, setAccessToken (state, token) {
      if (token) {
        const expAccessToken = jwtDecrypt(token).exp
        state.accessToken = token
        state.expAccessToken = JSON.parse(expAccessToken)
        localStorage.setItem(ACCESS_TOKEN, token)
        localStorage.setItem(EXP_ACCESS_TOKEN, JSON.parse(expAccessToken))
      }
    }, setRefreshToken (state, token) {
      if (token) {
        const expRefreshToken = jwtDecrypt(token).exp
        state.refreshToken = token
        state.expRefreshToken = JSON.parse(expRefreshToken)
        localStorage.setItem(REFRESH_TOKEN, token)
        localStorage.setItem(EXP_REFRESH_TOKEN, JSON.parse(expRefreshToken))
      }
    }, setUser (state, { userId, userRole }) {
      if (userId && userRole) {
        const user = {
          ...(localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)) : {}),
          id: userId,
          role: userRole
        }
        state.user = user
        localStorage.setItem(USER, JSON.stringify(user))
      }
    }, setEmail (state, email) {
      if (email) {
        const user = {
          email: email
        }
        state.user.email = email
        localStorage.setItem(USER, JSON.stringify(user))
      }
    }, setLogo(state, logo) {
      state.logo = logo
    }, setUserInfo(state, user) {
      if (user) {
        localStorage.setItem(USER, JSON.stringify(user))
        state.user = {
          ...state.user,
          ...user
        }
      }
    }, logout(state) {
      deleteCookies()
      state.accessToken = undefined
      state.refreshToken = undefined
      state.expAccessToken = undefined
      state.expRefreshToken = undefined
      state.setCounterCodeCheck = 0
      state.user = {
        id: undefined, email: undefined, name: undefined, role: undefined
      }
      localStorage.clear()
      // document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/') })
      setTimeout(() => router.push('/login'), 0)
    }, setTimerFromServer (state, timer) {
      state.timerFromServer = timer
    }
  }, actions: {
    async checkEmail ({ commit, state }, payload) {
      commit('setEmail', payload)
      commit('setLoading', true)
      try {
        const response = await axios.post('/api/auth/request', { email: payload })
        if (response.status === 204) {
          commit('setStepForm', 'code-form')
        }
      } catch (error) {
        commit('setEmailError', error.response.data.message || error.response.data.error)
      } finally {
        commit('setLoading', false)
      }
    },
    async checkCode ({ commit, state }, payload) {
      commit('setCodeError', null)
      commit('setLoading', true)
      const form = {
        email: state.user.email,
        code: payload
      }
      try {
        const { data } = await axios.post('/api/auth/confirm', form)
        commit('setCodeError', null)
        commit('setApprovePolitic', false)
        commit('setAccessToken', data.accessToken)
        commit('setRefreshToken', data.refreshToken)
        commit('setUser', data.accessToken ? jwtDecrypt(data.accessToken) : null)
        commit('setCounterCodeCheck', 0)
        commit('setStepForm', 'email-form')
      } catch (error) {
        commit('setApprovePolitic', true)
        commit('setCodeError', error.response.data.message)
        commit('setCounterCodeCheck', state.counterCodeCheck + 1)
        commit('setLoading', false)
        return Promise.reject(error.response.data.message)
      } finally {
        if (state.user.role === 'admin') {
          commit('setLoading', false)
        }
      }
    },
    async refreshToken ({ commit, state }) {
      commit('setLoading', true)
      try {
        const { data } = await axios.post('/api/auth/refresh', {
          refreshToken: state.refreshToken
        })
        console.log(data)
        if (!data.accessToken || !data.refreshToken) {
          commit('logout')
        } else {
          commit('setAccessToken', data.accessToken)
          commit('setRefreshToken', data.refreshToken)
        }
      } catch (error) {
        commit('logout')
        return Promise.reject(error)
      } finally {
        commit('setLoading', false)
      }
    }, async getInfo ({ commit, state }) {
      commit('setLoading', true)
      commit('setLogoLoading', true)
      try {
        const { data } = await axios.get('/api/user')
        if (data.role !== 'admin') {
          const { url } = await getFile(data.logoUrl)
          commit('setLogo', url)
        }
        commit('setUserInfo', data)
        return data
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('setLogoLoading', false)
        if (state.user.role !== 'employee') {
          commit('setLoading', false)
        }
      }
    }
  }, getters: {
    getAccessToken (state) {
      return state.accessToken
    }, getRefreshToken (state) {
      return state.refreshToken
    }, getUser (state) {
      return state.user
    }, getRole (state) {
      return state.user?.role
    }, getForm (state) {
      return state.stepForm
    }, isAuthenticated (state) {
      return !!state.accessToken
    }, getErrors (state) {
      return state.errors
    }, getApprovePolitic (state) {
      return state.approvePolitic
    }, isLoading (state) {
      return state.isLoading
    }, getCounter (state) {
      return state.counterCodeCheck
    }, getTimerFromServer (state) {
      return state.timerFromServer
    }, getLogo (state) {
      return state.logo
    }, getLogoLoading (state) {
      return state.isLogoLoading
    }
  }
}
