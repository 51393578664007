import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { computed } from 'vue'
import { deleteCookies, parseCookie } from '@/utils/cookie'
import { baseURL } from '@/utils/constants'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'catalog-main' },
    meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
    component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/UserHome.vue'),
    children: [
      {
        path: 'catalog',
        name: 'catalog-main',
        redirect: { name: 'catalog' },
        meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Catalog/CatalogMain.vue'),
        children: [
          {
            path: '',
            name: 'catalog',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Catalog/Catalog.vue')
          },
          {
            path: 'product/:productId',
            name: 'product',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Catalog/Product.vue')
          },
          {
            path: 'product-family/:productFamilyId',
            name: 'product-family',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Catalog/ProductFamily.vue')
          },
          {
            path: 'search',
            name: 'search',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Catalog/Search.vue')
          }
        ]
      },
      {
        path: 'orders',
        name: 'orders-main',
        redirect: 'orders',
        meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Orders/OrdersMain.vue'),
        children: [
          {
            path: '',
            name: 'orders',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Orders/Orders.vue')
          },
          {
            path: ':id',
            name: 'order',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Orders/Order.vue')
          }
        ]
      },
      {
        path: 'requests',
        name: 'requests-main',
        redirect: 'requests',
        meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Requests/RequestsMain.vue'),
        children: [
          {
            path: '',
            name: 'requests',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Requests/Requests.vue')
          },
          {
            path: 'create',
            name: 'request-create',
            meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Requests/RequestCreate.vue')
          }
        ]
      },
      {
        path: 'contacts',
        name: 'contacts',
        meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Contacts.vue')
      },
      {
        path: 'user-info/:userId',
        name: 'user-info',
        meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/UserInfo.vue')
      },
      {
        path: 'cart',
        name: 'cart',
        meta: { layout: 'User-Layout', auth: true, roles: ['employee'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/UserPages/Cart/CartMain.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: { name: 'clients-list' },
    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/AdminHome.vue'),
    children: [
      {
        path: 'administrators',
        name: 'administrators',
        redirect: { name: 'admins-list' },
        meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Administrators/Administrators'),
        children: [
          {
            path: '',
            name: 'admins-list',
            meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/AdminsPanel.vue')
          },
          {
            path: 'create-admin',
            name: 'create-admin',
            meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Administrators/CreateAdmin')
          },
          {
            path: 'edit-admin/:id',
            name: 'edit-admin',
            meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Administrators/EditAdmin')
          }
        ]
      },
      {
        path: 'clients',
        name: 'clients',
        redirect: { name: 'clients-list' },
        meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
        component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Clients.vue'),
        children: [
          {
            path: '',
            name: 'clients-list',
            meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/ClientsPanel.vue')
          },
          {
            path: 'create-client',
            name: 'create-client',
            meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/CreateClient.vue')
          },
          {
            path: ':clientId',
            name: 'client',
            redirect: { name: 'main-client' },
            meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
            component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Client.vue'),
            children: [
              {
                path: 'edit-client',
                name: 'edit-client',
                meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/EditClient.vue')
              },
              {
                path: '',
                name: 'main-client', // тут будет меню навигации для дочерних роутов
                // redirect: { name: 'about-client' },
                redirect: { name: 'about-client' },
                meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Main/MainClient.vue'),
                children: [
                  {
                    path: 'about',
                    name: 'about-client',
                    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Main/AboutClient.vue')
                  },
                  {
                    path: 'orders-client',
                    name: 'orders-client',
                    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Main/OrdersClient.vue')
                  },
                  {
                    path: 'requests-client',
                    name: 'requests-client',
                    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Main/RequestsClient.vue')
                  },
                  {
                    path: 'users',
                    name: 'users-list',
                    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/Main/Users.vue')
                  }
                ]
              },
              {
                path: 'create-user',
                name: 'create-user',
                meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/User/CreateUser.vue')
              },
              {
                path: 'user/:userId',
                name: 'main-user',
                meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/User/MainUser.vue'),
                children: [
                  {
                    path: '',
                    name: 'user',
                    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/User/User.vue')
                  },
                  {
                    path: 'edit-user',
                    name: 'edit-user',
                    meta: { layout: 'Admin-Layout', auth: true, roles: ['admin'] },
                    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPages/Clients/User/EditUser.vue')
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'Auth-Layout', auth: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/:NotFound(.*)*',
    name: 'NotFound',
    meta: { layout: 'General-Layout', auth: true, roles: ['admin', 'employee'] },
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  },
  {
    path: '/errors',
    name: 'Errors',
    meta: { layout: 'General-Layout', auth: true, roles: ['admin', 'employee'] },
    component: () => import(/* webpackChunkName: "about" */ '../views/Errors.vue')
  }
]
const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach(async (to, from, next) => {
  const isAuth = computed(() => store.getters['auth/isAuthenticated'])
  const role = store.getters['auth/getRole']
  const hasGuardPage = to.matched.some(record => record.meta['auth'])
  const isAccessForRole = to.matched.some(record => record.meta['roles']?.includes(role))
  const expAccess = computed(() => Number(localStorage.getItem('expAccessToken') + '000') > Date.now())
  const expRefresh = computed(() => Number(localStorage.getItem('expRefreshToken') + '000') > Date.now())

  // кейс для пользователя, который редиректнулся
  // если не авторизован и что-то есть в куках
  const { domain, accessToken, refreshToken } = parseCookie()
  if (!isAuth.value && domain) {
    // если поддомен сопадает с куком домена, то это наши токены, забираем их
    const urlDomain = window.location.hostname.split('.')[0]
    if (domain === urlDomain) {
      deleteCookies()
      store.commit('auth/setAccessToken', accessToken)
      store.commit('auth/setRefreshToken', refreshToken)
    }
    await store.dispatch('auth/refreshToken')
    await store.dispatch('auth/getInfo')
  }

  // кейс если мы не авторизованы и мы на поддомене

  if (!isAuth.value && window.location.hostname !== `portal.${baseURL}`) {
    const url = process.env.NODE_ENV === 'production' ? `https://portal.${baseURL}` : `https://portal.${baseURL}:8080`
    window.location.replace(url)
  }

  if (!expRefresh.value && isAuth.value) {
    store.commit('auth/logout')
    next('/login')
  }
  if (!expAccess.value && isAuth.value) {
    await store.dispatch('auth/refreshToken')
  }
  const goToStartPage = () => {
    if (role === 'admin') {
      next('/admin')
    } else {
      next('/')
    }
  }
  if (hasGuardPage && !isAuth.value) {
    next('/login')
  } else if (isAuth.value && to.path === '/login') {
    goToStartPage()
  } else if (isAuth.value && !isAccessForRole) {
    goToStartPage()
  } else {
    next()
  }
})
export default router
