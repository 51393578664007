<template>
  <button :class="['v-button', isType()]" :type="type">
    {{text}}
  </button>
</template>
<script>
export default {
  name: 'vButton',
  props: ['text', 'size', 'type'],
  methods: {
    isType() {
      return `v-button__${this.size}`
    }
  }
}
</script>
<style scoped lang="scss">

@import "../../assets/styles/variables";
  .v-button {
    outline: none;
    border: none;
    display: inline-block;
    border-radius: 32px;
    background: #C90D46;
    color: $color-white;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    &:hover {
      background-color: $color-red-hover;
    }

    &:active {
      box-shadow: inset 0 4px 4px #70101E;
    }

    &:disabled {
      pointer-events: none;
      background: $color-grey;
      cursor: default;
    }

    &__m {
      height: 35px;
      padding: 7px 20px;
      font-size: 16px;
      line-height: 19px;
    }

    &__l {
      height: 44px;
      padding: 10px 24px 12px;
      font-size: 18px;
      line-height: 22px;
    }
  }
</style>
