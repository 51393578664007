import axios from 'axios'

const defaultState = {
  users: [],
  user: {},
  total: null,
  pages: {
    total: 0,
    limit: 10,
    limitList: [10, 20, 30],
    page: 1
  },
  isLoading: false
}
export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    setUser(state, user) {
      state.user = user
    },
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setPages(state, pages) {
      state.pages = {
        ...state.pages,
        ...pages
      }
    }
  },
  actions: {
    async loadUsers({ commit }, payload) {
      commit('setLoading', true)
      const params = {
        clientId: payload.clientId,
        page: payload?.page ? parseInt(payload?.page, 10) : defaultState.pages.page,
        limit: payload?.limit ? parseInt(payload?.limit, 10) : defaultState.pages.limit
      }
      try {
        const { data } = await axios.get('/api/employees', {
          params
        })
        commit('setUsers', data.items)
        commit('setPages', {
          total: parseInt(data.paging.totalElements, 10),
          page: parseInt(data.paging.current, 10),
          limit: params.limit
        })
        commit('setLoading', false)
      } catch (error) {
        console.log('checkError', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadUserById({ commit }, id) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/employees/${id}`)
        commit('setUser', data)
      } catch (error) {
        console.log('loadUserById', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async createUser({ commit }, payload) {
      commit('setLoading', true)
      try {
        await axios.post('/api/employees', payload)
        commit('setLoading', false)
      } catch (error) {
        console.log('createUser', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async createUserFromFile({ commit }, payload) {
      commit('setLoading', true)
      try {
        await axios.post(
          '/api/employees/file',
          payload.formData,
          {
            params: { clientId: payload.clientId },
            headers: { 'Content-Type': 'multipart/form-data' }
          })
        return Promise.resolve()
      } catch (error) {
        console.log('createUser', error)
        return Promise.reject(error.response.data.message)
      } finally {
        commit('setLoading', false)
      }
    },
    async editUser({ commit }, payload) {
      commit('setLoading', true)
      try {
        await axios.put(`/api/employees/${payload.userId}`, payload.user)
        commit('setLoading', false)
      } catch (error) {
        console.log('editUser', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async blockUser({ commit }, userId) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/employees/${userId}/block`)
        commit('setLoading', false)
      } catch (error) {
        console.log('blockUser', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async deleteUser({ commit }, userId) {
      commit('setLoading', true)
      try {
        await axios.delete(`/api/employees/${userId}`)
        commit('setLoading', false)
      } catch (error) {
        console.log('deleteUser', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async repeatEmail({ commit }, userId) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/employee-create-event/${userId}`)
        commit('setLoading', false)
      } catch (error) {
        console.log('repeatEmail', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getUser(state) {
      return state.user
    },
    getPages(state) {
      return state.pages
    },
    isLoading(state) {
      return state.isLoading
    }
  }
}
