<template>
  <div class="counter" @click.stop>
    <div class="wrapper-operator wrapper-operator__minus" :class="{ 'wrapper-operator_disabled': counter <= min }" @click.stop="decrement(+$refs.input.value)">
      <div class="counter__operator"></div>
    </div>
    <input
      class="counter__input"
      :class="{ 'counter__input_error': error }"
      :maxlength="6"
      type="text"
      :placeholder="counter"
      :value="counter"
      v-maska="notZeroStart"
      ref="input"
      @input="inputChangeHandler"
      @keydown="onKeydownHandler"
    />
    <div class="counter__error" v-if="error">
      <p v-if="error === 'max'">Максимум {{ max }} шт</p>
      <p v-else>Минимум {{ min }} шт</p>
    </div>
    <div class="wrapper-operator" :class="{ 'wrapper-operator_disabled': counter >= max }" @click.stop="increment(+$refs.input.value)">
      <div class="counter__operator counter__operator-plus"></div>
    </div>
    <span class="counter__force-update">{{ updating }}</span>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { maska } from 'maska'

import { notZeroStart } from '@/utils/inputMasks'

export default {
  name: 'vCounterInput',
  props: ['counter', 'minQuantity', 'maxQuantity'],
  directives: { maska },
  emits: ['update:counter'],
  setup(props, { emit }) {
    const min = ref(props.minQuantity || 1)
    const max = ref(props.maxQuantity || 999999)
    const error = ref(null)
    const updating = ref(false)

    const changeCounter = value => {
      if (+value > 0) {
        emit('update:counter', value)
      }
    }

    const forceUpdate = () => {
      updating.value = !updating.value
    }

    const inputChangeHandler = event => {
      let value = +event.target.value
      if (Number.isNaN(value)) {
        event.preventDefault()
      } else if (value > -1) {
        if (value === 0) {
          changeCounter(0)
        } else if (value < min.value) {
          error.value = 'min'
          // value = min.value
          forceUpdate()
        } else if (value > max.value) {
          error.value = 'max'
          value = max.value
          forceUpdate()
        } else {
          error.value = null
        }
        changeCounter(value)
      }
    }

    const decrement = (value) => {
      if (value === 0) {
        forceUpdate()
        return
      } else if (value > max.value) {
        value = props.maxQuantity
      } else {
        value = value === min.value ? min.value : value - 1
      }
      error.value = null
      changeCounter(value)
    }

    const increment = (value) => {
      if (value === 0) {
        forceUpdate()
        return
      } else if (value < min.value) {
        value = props.minQuantity
      } else {
        value = value >= max.value ? max.value : value + 1
      }
      error.value = null
      changeCounter(value)
    }

    const onKeydownHandler = event => {
      if (event.key === 'ArrowUp') {
        increment(+event.target.value)
      } else if (event.key === 'ArrowDown') {
        decrement(+event.target.value)
      }
    }

    onMounted(() => {
      if (props.counter < min.value) {
        error.value = 'min'
      } else if (props.counter > max.value) {
        error.value = 'max'
      }
    })

    return { changeCounter, inputChangeHandler, onKeydownHandler, notZeroStart, min, max, increment, decrement, error, updating }
  }
}
</script>

<style lang="scss" scoped>
.counter {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 1;

  &__input {
    width: 47px;
    height: 35px;
    background: $color-white;
    border: 1px solid $color-border-grey;
    border-radius: 16px;
    color: $color-black;
    font-size: 16px;
    line-height: 21px;
    padding: 0 5px;
    text-align: center;
    user-select: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: $color-grey;
    }

    &_error {
      border-color: $color-red;
    }
  }

  &__operator {
    position: relative;
    width: 10px;
    height: 2px;
    background-color: $color-green;

    &-plus {
      &::before {
        content: '';
        display: inherit;
        width: 100%;
        height: 100%;
        transform: rotateZ(90deg);
        background-color: inherit;
      }
    }
  }

  &__error {
    position: absolute;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    color: $color-red;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__force-update {
    position: absolute;
    top: -9999px;
    opacity: 0;
  }
}

.wrapper-operator {
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: $color-green;

    .counter__operator {
      background-color: $color-white;
    }
  }

  &_disabled {
    pointer-events: none;

    .counter__operator {
      background-color: $color-grey;
    }
  }

  &__minus:hover {
    background-color: $color-red;
  }
}
</style>
