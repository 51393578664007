import axios from 'axios'

const defaultState = {
  result: [],
  hints: [],
  value: '',
  language: 'ru',
  abortDebounce: true,
  clearValue: false,
  pages: {
    limit: 10,
    limitList: [10, 25, 50, 100],
    page: 1,
    total: null
  },
  isLoading: false
}

export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setResult(state, result) {
      state.result = result
    },
    setHints(state, hints) {
      state.hints = hints
    },
    setTotal(state, total) {
      state.pages.total = total
    },
    setLimit(state, limit) {
      state.pages.limit = limit
    },
    setPage(state, page) {
      state.pages.page = page
    },
    setValue(state, value) {
      state.value = value
      state.abortDebounce = false
    },
    setAbortDebounce(state, bool) {
      state.abortDebounce = bool
      state.hints = []
    },
    changeClearValue(state) {
      state.clearValue = !state.clearValue
    }
  },
  actions: {
    async loadResult({ commit }, payload) {
      commit('setLoading', true)
      commit('setHints', [])
      try {
        const { data } = await axios.get('/api/catalog/search', {
          params: {
            page: +payload?.page || defaultState.pages.page,
            limit: +payload?.limit || defaultState.pages.limit,
            query: payload.query,
            language: payload.language
          }
        })
        commit('setLimit', +payload?.limit || defaultState.pages.limit)
        commit('setPage', +payload?.page || defaultState.pages.page)
        commit('setResult', data.items)
        commit('setTotal', data.paging.totalElements)
        commit('setLoading', false)
      } catch (error) {
        console.log('loadResult', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadHints({ commit }, payload) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/catalog/search', {
          params: {
            page: 1,
            limit: 10,
            query: payload.query,
            language: payload.language
          }
        })
        commit('setHints', data.items)
        commit('setLoading', false)
      } catch (error) {
        console.log('loadHints', error)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    getResult(state) {
      return state.result
    },
    getHints(state) {
      return state.hints
    },
    getPages(state) {
      return state.pages
    },
    getValue(state) {
      return state.value
    },
    getAbortDebounce(state) {
      return state.abortDebounce
    },
    getLanguage(state) {
      return state.language
    },
    getClearValue(state) {
      return state.clearValue
    }
  }
}
