<template>
  <div :class="['v-checkbox', `v-checkbox--${size}`]">
    <input ref="checkbox" type="checkbox" class="v-checkbox__input custom-checkbox" :id="className" :name="className" :value="modelValue" :checked="checked" @change="$emit('update:modelValue', $event.target.checked)">
    <label :for="className" :class="['v-checkbox__label', `v-checkbox__label--${size}`]">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'vCheckbox',
  props: ['modelValue', 'className', 'size', 'checked'],
  emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>
.v-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__label--m::before  {
    width: 18px;
    height: 18px;
  }
  &__label__l::before  {
    width: 24px;
    height: 24px;
  }
}
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  width: 18px;
  height: 18px;
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75% 75%;
}
.custom-checkbox:checked+label::before {
  background-image: url("../../assets/icons/check.svg");
}

/* стили при наведении курсора на checkbox */
//.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
//  border-color: #b3d7ff;
//}
/* стили для активного состояния чекбокса (при нажатии на него) */
//.custom-checkbox:not(:disabled):active+label::before {
//  background-color: #b3d7ff;
//  border-color: #b3d7ff;
//}
/* стили для чекбокса, находящегося в фокусе */
//.custom-checkbox:focus+label::before {
//  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
//}
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
//.custom-checkbox:focus:not(:checked)+label::before {
//  border-color: #80bdff;
//}
/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled+label::before {
  background-color: $color-border-grey;
  //background-image: url("../assets/icons/check-disabled.svg");
}

</style>
