<template>
  <button :class="['v-button', isType()]" :type="type">
    {{text}}
  </button>
</template>
<script>
export default {
  name: 'vFillGreenButton',
  props: ['text', 'size', 'type'],
  methods: {
    isType() {
      return `v-button__${this.size}`
    }
  }
}
</script>
<style scoped lang="scss">

@import "../../assets/styles/variables";
  .v-button {
    outline: none;
    border: none;
    display: inline-block;
    border-radius: 32px;
    background: $color-green-dark;
    color: $color-white;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    &:hover {
      background-color: #229753;
    }

    &:active {
      box-shadow: inset 0 4px 4px #1d8147;
    }

    &:disabled {
      background: $color-grey;
      cursor: default;
    }

    &__m {
      padding: 8px 20px;
      font-size: 16px;
      line-height: 19px;
    }

    &__l {
      padding: 10px 24px 12px;
      font-size: 18px;
      line-height: 22px;
    }
  }
</style>
