<template>
  <div class="search">
    <SearchIcon class="search__logo" />
    <input class="search__input" :value="modelValue" type="text" :placeholder="placeholder"  @input="onInput" />
  </div>
</template>

<script>
import SearchIcon from '@/components/icons/search-icon.vue'

export default {
  name: 'vSearchInput',
  components: { SearchIcon },
  props: ['placeholder', 'modelValue'],
  emits: ['update:modelValue'],
  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  display: flex;
  align-items: center;
  background: $color-white;
  border: 1px solid $color-grey-border;
  border-radius: 24px;
  padding: 0 20px;

  &__logo {
    margin-right: 10px;
  }

  &__input {
    width: 100%;
    font-size: 18px;
    line-height: 18px;
    border: none;

    &::placeholder {
      color: $color-grey;
    }
  }
}
</style>
