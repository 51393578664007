import axios from 'axios'

const defaultState = {
  requests: [],
  request: {},
  isLoading: false,
  counters: {},
  pages: {
    limit: 10,
    page: 1,
    limitList: [10, 20, 50, 100],
    total: null
  }
}

export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setRequests(state, requests) {
      state.requests = requests
    },
    setRequest(state, request) {
      state.request = request
    },
    setTotal(state, total) {
      state.pages.total = total
    },
    setLimit(state, limit) {
      state.pages.limit = limit
    },
    setPage(state, page) {
      state.pages.page = page
    },
    setCounters(state, counters) {
      state.counters = counters
    }
  },
  actions: {
    async loadRequests({ commit, dispatch }, payload) {
      commit('setLoading', true)
      try {
        const url = payload.isAdmin ? '/api/requests/all' : '/api/requests'
        const params = {
          status: payload?.status || 'working',
          page: +payload?.page || defaultState.pages.page,
          limit: +payload?.limit || defaultState.pages.limit
        }
        if (payload.isAdmin) {
          params.clientId = payload.clientId
        }
        const { data } = await axios.get(url, { params })
        await dispatch('loadCounterStatuses', { isAdmin: payload.isAdmin, clientId: payload?.clientId || null })
        commit('setLimit', +payload?.limit || defaultState.pages.limit)
        commit('setPage', +payload?.page || defaultState.pages.page)
        commit('setTotal', data.paging.totalElements)
        commit('setRequests', data.items)
      } catch (error) {
        console.log('loadRequests', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadCounterStatuses({ commit }, payload) {
      try {
        const url = payload.isAdmin ? '/api/requests/all' : '/api/requests'
        const workingResponse = await axios.get(url, { params: { status: 'working', page: 1, limit: 10000, clientId: payload?.clientId || null } })
        const awaitApprovalResponse = await axios.get(url, { params: { status: 'await_approval', page: 1, limit: 10000, clientId: payload?.clientId || null } })
        const agreedResponse = await axios.get(url, { params: { status: 'agreed', page: 1, limit: 10000, clientId: payload?.clientId || null } })
        const rejectedResponse = await axios.get(url, { params: { status: 'rejected', page: 1, limit: 10000, clientId: payload?.clientId || null } })
        commit('setCounters', {
          working: workingResponse.data.paging.totalElements,
          await_approval: awaitApprovalResponse.data.paging.totalElements,
          agreed: agreedResponse.data.paging.totalElements,
          rejected: rejectedResponse.data.paging.totalElements
        })
      } catch (error) {
        console.log('loadCounterStatuses', error)
      }
    },
    async loadRequestById({ commit }, id) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/request/${id}`)
        commit('setRequest', data)
      } catch (error) {
        console.log('loadRequestById', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async createRequest({ commit }, payload) {
      commit('setLoading', true)
      try {
        await axios.post('/api/request', payload)
      } catch (error) {
        console.log('createRequest', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async agreement({ commit, dispatch }, { id, decision, limit, page }) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/request/agreement/${id}/${decision}`)
        await dispatch('loadRequests', {
          status: 'await_approval',
          limit,
          page,
          isAdmin: false
        })
      } catch (error) {
        console.log('agreement', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async respond({ commit, dispatch }, { id, decision, values, limit, page }) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/request/${id}/${decision}`, values)
        await dispatch('loadRequests', {
          status: 'working',
          limit,
          page,
          isAdmin: true
        })
      } catch (error) {
        console.log('respond', error)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getRequests(state) {
      return state.requests
    },
    getRequest(state) {
      return state.request
    },
    isLoading(state) {
      return state.isLoading
    },
    getPages(state) {
      return state.pages
    },
    getCounters(state) {
      return state.counters
    }
  }
}
