<template>
  <button class="v-icon-button">
    <slot></slot>
    <span class="v-icon-button__text">
      {{text}}
    </span>
  </button>
</template>
<script>
export default {
  name: 'vIconButton',
  props: ['text'],
  methods: {
    isType() {
      return `v-button__${this.size}`
    }
  }
}
</script>
<style scoped lang="scss">
.v-icon-button {
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  color: $color-black;
  background: transparent;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover &__text{
    color: $color-red-hover;
  }

  &__text {
    margin-left: 8px;
    padding: 6px 0;
  }
}
</style>
