import axios from 'axios'

const defaultState = {
  data: {},
  language: 'ru',
  isLoading: false
}
export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setLoading(state, bool) {
      state.isLoading = bool
    },
    clearFamily(state) {
      state.data = {}
    }
  },
  actions: {
    async loadProduct({ commit }, payload) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/products/${payload.id}`, {
          params: {
            language: payload.language
          }
        })
        commit('setData', data)
        commit('setLoading', false)
      } catch (error) {
        console.log('loadProduct', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadFamily({ commit }, payload) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/families/${payload.id}`, {
          params: {
            language: payload.language
          }
        })
        commit('setData', data)
        commit('setLoading', false)
      } catch (error) {
        console.log('loadFamily', error)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getData(state) {
      return state.data
    },
    getLanguage(state) {
      return state.language
    },
    isLoading(state) {
      return state.isLoading
    }
  }
}
