<template>
  <div class="textarea-block" :class="{ error }">
    <label for="textarea" class="label" :class="{ active: modelValue || isFocus }" >{{ label }}</label>
    <textarea
      id="textarea"
      :minlength="minlength"
      :maxlength="maxlength"
      :value="modelValue"
      class="textarea"
      :class="{ disabled }"
      v-maska="mask"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="isFocus = true"
      @blur="isFocus = false"
    ></textarea>
  </div>
</template>

<script>
import { maska } from 'maska'

export default {
  name: 'vTextarea',
  props: ['label', 'error', 'modelValue', 'minlength', 'maxlength', 'mask', 'disabled', 'handleChange'],
  directives: { maska },
  emits: ['update:modelValue'],
  data: () => ({
    isFocus: false
  }),
  watch: {
    isFocus() {
      if (!this.isFocus) {
        this.handleChange(this.modelValue)
      }
    },
    modelValue() {
      if (!this.error) {
        this.handleChange(this.modelValue, false)
      } else {
        this.handleChange(this.modelValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea-block {
  width: 100%;
  min-height: 82px;
  background-color: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 16px;
  padding: 8px 20px;

  &.error {
    border-color: $color-red;
  }

  .label {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    color: $color-grey;
    transition: 0.2s;

    &.active {
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  .textarea {
    display: inline-table;
    width: 100%;
    min-height: 42px;
    font-size: 16px;
    line-height: 21px;
    color: $color-black;
    border: none;
    resize: none;

    &.disabled {
      color: $color-grey;
    }
  }
}
</style>
