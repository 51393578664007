<template>
  <div v-show="total && total > limitList[0]" class="pagination">
    <div class="info">
      <v-select-mini v-model="amountValue" :options="amountItemsList" class="select"></v-select-mini>
      <p class="items">
        <span class="currentNumbersOfItems">
          {{ startNumberOfItems !== endNumberOfItems ? `${startNumberOfItems}-${endNumberOfItems}` : endNumberOfItems }}
        </span>
        &nbsp;из {{ total }}
      </p>
    </div>
    <div class="main">
      <div class="cell main-cell" :class="{ active: page === P1, hide: maxPage === 1 }" :data-page="P1" @click="cellClickHandler">{{ P1 }}</div>
      <div class="cell main-cell" :class="{ active: page === P2, hide: maxPage < 2 }" :data-page="P2" @click="cellClickHandler">{{ P2 }}</div>
      <div class="cell main-cell" :class="{ active: page === P3, hide: maxPage < 3 }" :data-page="P3" @click="cellClickHandler">{{ P3 }}</div>
      <div class="cell main-cell dots-block" :class="{ hide: maxPage < 8 }"><dots-icon class="dots-icon"></dots-icon></div>
      <div class="cell main-cell" :class="{ active: page === 4, hide: maxPage > 7 || maxPage < 4 }" :data-page="4" @click="cellClickHandler">4</div>
      <div class="cell main-cell" :class="{ active: page === P4, hide: maxPage < 5 }" :data-page="P4" @click="cellClickHandler">{{ P4 }}</div>
      <div class="cell main-cell" :class="{ active: page === P5, hide: maxPage < 6 }" :data-page="P5" @click="cellClickHandler">{{ P5 }}</div>
      <div class="cell main-cell" :class="{ active: page === P6, hide: maxPage < 7 }" :data-page="P6" @click="cellClickHandler">{{ P6 }}</div>
    </div>
    <div class="switch" :class="{ hide: maxPage < 8 }">
      <input
        class="input"
        :class="{ error }"
        min="1"
        :max="maxPage"
        type="number"
        v-maska="notZeroStart"
        placeholder="N°"
        @keypress.enter="inputKeypressHandler($event.target.value)"
        @input="changeValue($event.target.value)"
        ref="switchPage"
      />
      <button class="switch-button" @click="switchClickHandler">Перейти</button>
    </div>
  </div>
</template>

<script>
import { maska } from 'maska'
import DotsIcon from '../icons/dots-icon.vue'
import { notZeroStart } from '@/utils/inputMasks'

export default {
  name: 'vPagination',
  emits: ['changePage', 'changeLimit'],
  props: ['total', 'page', 'limitList', 'limit'],
  directives: { maska },
  components: { DotsIcon },
  data: () => ({
    amountValue: null,
    error: '',
    notZeroStart,
    isScrollToDown: false
  }),
  computed: {
    startNumberOfItems() {
      return (this.page * this.amountValue) - this.amountValue + 1
    },
    endNumberOfItems() {
      const result = this.startNumberOfItems - 1 + this.amountValue
      return result > this.total ? this.total : result
    },
    maxPage() {
      return +Math.ceil(this.total / this.amountValue) || 1
    },
    amountItemsList() {
      return this.limitList.map((el, index) => ({
        id: `pagination_${index}`,
        text: `По ${el}`,
        value: el
      }))
    },
    P1() {
      if (this.maxPage <= 7) return 1
      if (Math.round(this.page / this.maxPage) === 1) {
        return 1
      } else {
        return (this.page < 3) ? 1 : this.page - 1
      }
    },
    P2() {
      if (this.maxPage <= 7) return 2
      if (Math.round(this.page / this.maxPage) === 1) {
        return 2
      } else {
        return (this.page < 3) ? 2 : this.page
      }
    },
    P3() {
      if (this.maxPage <= 7) return 3
      if (Math.round(this.page / this.maxPage) === 1) {
        return 3
      } else {
        return (this.page < 3) ? 3 : this.page + 1
      }
    },
    P4() {
      if (this.maxPage <= 7) return 5
      if (Math.round(this.page / this.maxPage) === 0) {
        return this.maxPage - 2
      } else {
        return (this.page + 2 > this.maxPage) ? this.maxPage - 2 : this.page - 1
      }
    },
    P5() {
      if (this.maxPage <= 7) return 6
      if (Math.round(this.page / this.maxPage) === 0) {
        return this.maxPage - 1
      } else {
        return (this.page + 2 > this.maxPage) ? this.maxPage - 1 : this.page
      }
    },
    P6() {
      if (this.maxPage <= 7) return 7
      if (Math.round(this.page / this.maxPage) === 0) {
        return this.maxPage
      } else {
        return (this.page + 2 > this.maxPage) ? this.maxPage : this.page + 1
      }
    }
  },
  methods: {
    setPage(page) {
      if (page > this.maxPage || page < 1) {
        this.error = 'Такая страница не существует'
      } else {
        this.$emit('changePage', +page)
        this.$refs.switchPage.value = ''
        this.error = ''
      }
      this.isScrollToDown = true
    },
    changeValue(page) {
      if (page > this.maxPage || page < 1) {
        this.error = 'Такая страница не существует'
      } else {
        this.error = ''
      }
    },
    inputKeypressHandler(page) {
      this.setPage(page)
    },
    switchClickHandler() {
      this.setPage(this.$refs.switchPage.value)
    },
    cellClickHandler(event) {
      this.setPage(event.target.dataset.page)
    }
  },
  watch: {
    amountValue() {
      this.$emit('changeLimit', this.amountValue)
    },
    limit() {
      this.setPage(1)
      this.amountValue = this.limit
    },
    page() {
      if (this.isScrollToDown) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  },
  mounted() {
    this.amountValue = this.limit
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;

  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 36px;
    font-size: 16px;
    line-height: 19px;
    color: $color-red;
    background: $color-white;
    border: 1px solid rgba($color-red, 0.2);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &.active {
      color: $color-black;
      border-color: $color-grey;
      pointer-events: none;
    }

    &.hide {
      display: none;
    }
  }

  .info {
    display: flex;
    font-size: 16px;
    line-height: 21px;

    .select {
      height: 35px;
      min-width: 106px;
    }

    .items {
      display: flex;
      align-items: center;
      margin-left: 24px;
      white-space: nowrap;

      .currentNumbersOfItems{
        font-weight: 700;
      }
    }
  }

  .main {
    position: absolute;
    display: flex;
    left: 50%;
    transform: translateX(-50%);

    .main-cell {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    .dots-block {
      border-color: $color-grey;
      pointer-events: none;

      .dots-icon {
        margin-top: 8px;
        fill: $color-grey;
      }
    }
  }

  .switch {
    display: flex;
    height: 100%;

    &.hide {
      display: none;
    }

    .input {
      width: 58px;
      background: $color-white;
      border: 1px solid $color-border-grey;
      border-radius: 16px;
      user-select: none;
      text-align: center;
      margin-right: 12px;
      color: $color-black;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:hover,
      &:focus {
        -moz-appearance: number-input;
      }

      &::placeholder {
        color: $color-grey;
      }

      &.error {
        border-color: $color-red;
      }
    }

    .switch-button {
      font-size: 16px;
      line-height: 21px;
      color: $color-red;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
