import axios from 'axios'
import store from './store'
import router from '@/router'

axios.defaults.baseURL = 'https://portal.esupply.softline.com'

axios.interceptors.request.use(async config => {
  const isAuth = store.getters['auth/isAuthenticated']
  // если ещё не авторизован, то пропускаем запросы - проверки email и code
  if (!isAuth || config.url === '/api/auth/refresh') {
    return config
  } else { // иначе если мы авторизованы, логика идёт с токенами - проверяем живы ли токены
    // жив ли AccessToken
    const expAccess = (Number(localStorage.getItem('expAccessToken') + '000') || 1) > Date.now()
    // жив ли RefreshToken
    const expRefresh = (Number(localStorage.getItem('expRefreshToken') + '000') || 1) > Date.now()
    const accessToken = store.getters['auth/getAccessToken']
    // если токены живы то пропускаем иначе проверка что стухло
    if (expAccess) {
      config.headers.Authorization = accessToken
      return config
    } else { // accessToken стух, требуется обнвоить пару через Refresh или сделать логаут или отложить запросы в капилку
      // если стух refreshToken - делаем logout
      if (!expRefresh) {
        store.commit('auth/logout')
        isRefreshing = false
        // subscribers = []
        return router.push('/login')
      } else { // иначе начинается серьёзная работа
        // если мы ещё не обновили refresh то обновляем
        if (!isRefreshing) {
          isRefreshing = true
          try {
            await store.dispatch('auth/refreshToken')
            // subscribers.forEach(configItem => axios(configItem))
            config.headers.Authorization = store.getters['auth/getAccessToken']
            return config
          } catch (error) {
            store.commit('auth/logout')
            return router.push('/login')
          } finally {
            isRefreshing = false
            // subscribers = []
          }
        } else { // если refreshToken пошёл обновляться, ждём обновления пары токенов в цикле
          // todo Здесь надо доработать логику чтобы если много запросов идёт, первый улетает в обновление рефреш токена,
          //  а остальные должны накапливается, дожидались обновления рефреш токена и только потом отправлялись
          //  сейчас стоит логика обновления рефреш токена ещё до прехода на странциу с запросами, на router.before
          const prom1 = setTimeout(() => { Promise.resolve() }, 1000)
          Promise.all([prom1])
            .then(() => {
              config.headers.Authorization = store.getters['auth/getAccessToken']
              return config
            })
          // subscribers.push(config)
        }
      }
    }
  }
}, error => {
  return Promise.reject(error)
})

// let subscribers = []
let isRefreshing = false

axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config
    if (error.response.data.retryAfter) {
      store.commit('auth/setTimerFromServer', error.response.data.retryAfter)
    }
    // забираем originalRequest из овтета неудавлегося запроса, чтобы потмо его повторить с тем же конфигом
    if (error.response.status === 401) {
      if (error.response.config.url === '/api/auth/refresh') {
        store.commit('auth/logout')
        return
      }

      // Ставим флаг originalRequest._retry чтобы повторно запросы не попадали в цикл,
      // и запрос после получения Refresh был всего лишь 1 раз
      originalRequest._retry = true
      // originalRequest.headers.Authorization = store.getters['auth/getAccessToken']
      // return originalRequest
      // Отправляем неудачный запрос в копилку запросов, откуда потом. после получения RefreshToken повторим отправку всех запросов
      // eslint-disable-next-line no-unreachable
      if (!isRefreshing) {
        isRefreshing = true
        store.dispatch('auth/refreshToken')
          .then(() => {
            axios.defaults.headers.common['Authorization'] = store.getters['auth/getRefreshToken']
            // делаем отправку всех запросов. которые ожидали обновления пары токенов
            return axios(originalRequest)
          })
          .catch(() => {
            // если запрос RefreshToken увенчался ошибкой 400 или 500 то разлогиниваем и отправляем на странциу авторизации
            router.push('login')
          })
          .finally(() => {
            // после обвноления refreshToken возвращаем флаг isRefreshing в состояние, что мы перестали заправшивать RefreshToken и очищаем очередь запросов
            isRefreshing = false
            // subscribers = []
          })
      } else {
        return originalRequest
      }
    }
    return Promise.reject(error)
  })
