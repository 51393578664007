<template>
  <div
    v-click-outside="clickOutside"
    class="select"
    :class="{ bigSelect: text, disabled, error }"
    :tabindex="disabled ? -1 : 0"
    @focus.stop="isActive = true"
    @blur="isActive = false"
    ref="select"
  >
    <div class="label" :class="{ active: text }">{{ label }}</div>
    <p v-if="text" class="text" :class="{ disabled }">{{ text }}</p>
    <div class="icon-container">
      <DropDownIcon class="icon" :class="{ active: isActive, disabled }" />
    </div>
    <transition :name="transitionOption">
      <ul v-if="isActive" :class="['list', {'list--down': transitionOption === 'animate-down' }, {'list--top': transitionOption === 'animate-top'} ]">
        <li
          v-for="(item, index) in options"
          :key="item.id"
          class="item"
          :class="{ active: index === indexActiveOption }"
          @click="itemClickHandler(index)"
        >
          {{ item.text }}
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import DropDownIcon from '../icons/dropdown-icon.vue'
import clickOutside from '@/directives/click-outside'

export default {
  name: 'vSelectMini',
  components: { DropDownIcon },
  props: ['options', 'label', 'modelValue', 'disabled', 'error'],
  directives: { clickOutside },
  emits: ['update:modelValue'],
  data: () => ({
    isActive: false,
    indexActiveOption: -1,
    isMounted: false
  }),
  mounted() {
    this.isMounted = true
  },
  computed: {
    text() {
      return this.modelValue ? this.options.find(el => el.value === this.modelValue).text : ''
    },
    transitionOption () {
      if (this.isMounted) {
        return this.$refs.select.getBoundingClientRect().y > window.innerHeight / 2 ? 'animate-down' : 'animate-top'
      } else {
        return 'animate-down'
      }
    }
  },
  watch: {
    isActive() {
      if (this.isActive) {
        document.addEventListener('keydown', this.keydownHandler)
      } else {
        document.removeEventListener('keydown', this.keydownHandler)
      }
    }
  },
  methods: {
    passValueToParent(index) {
      this.$emit('update:modelValue', this.options[index].value)
    },
    itemClickHandler(index) {
      this.indexActiveOption = index
      this.passValueToParent(index)
      this.$refs.select.blur()
    },
    closeList() {
      this.isActive = false
      this.indexActiveOption = this.options.findIndex(el => el.value === this.modelValue)
    },
    clickOutside() {
      this.closeList()
    },
    keydownHandler(event) {
      const EVENT_KEYS = ['Escape', 'ArrowDown', 'ArrowUp', 'Enter']
      if (EVENT_KEYS.includes(event.key)) event.preventDefault()
      if (event.key === 'Escape') {
        this.closeList()
        this.$refs.select.blur()
      }
      if (this.isActive && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
        if (event.key === 'ArrowDown') {
          if (this.indexActiveOption === this.options.length - 1) {
            return
          } else {
            this.indexActiveOption += 1
          }
        }
        if (event.key === 'ArrowUp') {
          if (this.indexActiveOption <= 0) {
            this.indexActiveOption = 0
          } else {
            this.indexActiveOption -= 1
          }
        }
      }
      if (this.isActive && event.key === 'Enter') {
        this.passValueToParent(this.indexActiveOption)
        this.isActive = false
        this.$refs.select.blur()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.select {
  position: relative;
  width: 100%;
  height: 35px;
  padding: 6px 20px;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 16px;
  transition: 0.5s;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.disabled {
    pointer-events: none;
    user-select: none;
  }

  &.error {
    border-color: $color-red;
  }

  &.bigSelect {
    height: 49px;
  }

  .label {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    line-height: 20.8px;
    color: $color-grey;
    transition: 0.5s;

    &.active {
      height: auto;
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 20.8px;
    color: $color-black;

    &.disabled {
      color: $color-grey;
    }
  }

  .icon-container {
    width: 16px;
    height: 12px;
    position: relative;
    margin-left: 10px;
  }
  .icon {
    position: absolute;
    //top: 50%;
    //right: 20px;
    //transform: translateY(-50%);
    transition: 0.5s cubic-bezier(1, -0.42, 0, 1.32);
    stroke: $color-red;

    &.active {
      transform: rotateZ(-180deg);
    }

    &.disabled {
      stroke: $color-grey;
    }
  }

  .list {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 12px 20px;
    background: $color-white;
    border-radius: 16px;
    border: 1px solid $color-border-grey;
    z-index: 100;

    &--down {
      top:100%
    }

    &--top{
      bottom: 100%
    }

    .item {
      font-size: 16px;
      line-height: 20.8px;
      color: #171C20;
      padding: 4px 0;
      cursor: pointer;

      &:hover,
      &.active {
        color: $color-red;
      }
    }
  }

  .animate-down {
    &-enter-active {
      transition: all .5s ease;
    }

    &-leave-active {
      pointer-events: none;
      transition: all .5s ease;
    }

    &-enter-from,
    &-leave-to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
  .animate-top {
    &-enter-active {
      transition: all .5s ease;
    }

    &-leave-active {
      pointer-events: none;
      transition: all .5s ease;
    }

    &-enter-from,
    &-leave-to {
      transform: translateY(50px);
      opacity: 0;
    }
  }
}
</style>
