import vFilePicker from './v-file-picker'
import vSelect from './v-select'
import vSelectMini from './v-select-mini'
import vTabs from './v-tabs'
import vButton from './v-button'
import vIconButton from './v-icon-button'
import vInput from './v-input'
import vError from './v-error'
import vSpinner from './v-spinner'
import vTitle from './v-title'
import vNavigation from './v-navigation'
import vCheckbox from './v-checkbox'
import vBorderButton from './v-border-button'
import vRadioGroup from './v-radio-group'
import vRadioButton from './v-radio-button'
import vTextarea from './v-textarea'
import vModal from './v-modal'
import vPagination from './v-pagination'
import vDatePicker from './v-date-picker'
import vGreenButton from './v-green-button'
import vFillGreenButton from './v-fill-green-button'
import vSearchInput from './v-search-input'
import vCounterInput from './v-counter-input'
import vUserMenu from './v-user-menu'
import vCartItem from './v-cart-item'
import vCalendar from './v-calendar'
import vStatusTooltip from './v-status-tooltip'

export default [
  vTabs, vButton, vIconButton, vSpinner, vInput, vSelect, vSelectMini, vCheckbox, vError, vTitle, vNavigation, vFilePicker,
  vBorderButton, vRadioGroup, vRadioButton, vTextarea, vModal, vPagination, vDatePicker, vGreenButton, vSearchInput,
  vCounterInput, vUserMenu, vCalendar, vStatusTooltip, vCartItem, vFillGreenButton
]
