import axios from 'axios'

const defaultState = {
  items: [],
  allItems: [],
  totalCost: 0,
  totalNds: 0,
  totalQuantity: null,
  language: 'ru',
  isLoading: false,
  pages: {
    limit: 10,
    limitList: [10, 20, 30],
    page: 1,
    total: null
  }
}

export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setItems(state, items) {
      state.items = items
    },
    setAllItems(state, items) {
      state.allItems = items
    },
    setTotal(state, total) {
      state.pages.total = total
    },
    setTotalCost(state, totalCost) {
      state.totalCost = totalCost
    },
    setTotalNds(state, totalNds) {
      state.totalNds = totalNds
    },
    setPage(state, page) {
      state.pages.page = page
    },
    setLimit(state, limit) {
      state.pages.limit = limit
    },
    setAmount(state, payload) {
      state.items.find(item => payload.id === item.id).amount = payload.amount
    },
    setTotalQuantity(state, totalQuantity) {
      state.totalQuantity = totalQuantity
    }
  },
  actions: {
    async loadItems({ commit }, payload) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/cart', {
          params: {
            page: +payload?.page || defaultState.pages.page,
            limit: +payload?.limit || defaultState.pages.limit,
            language: +payload?.language || defaultState.language
          }
        })
        commit('setItems', data.items)
        commit('setTotal', data.paging.totalElements)
        commit('setTotalCost', data.totalCost)
        commit('setTotalNds', data.totalNds)
        commit('setTotalQuantity', data.totalQuantity)
        commit('setPage', +payload?.page || defaultState.pages.page)
        if (+payload?.limit !== 10000) {
          commit('setLimit', +payload?.limit || defaultState.pages.limit)
        }
      } catch (error) {
        console.log('loadItems', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadAllItems({ commit }) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/cart', {
          params: {
            page: 1,
            limit: 10000,
            language: defaultState.language
          }
        })
        commit('setAllItems', data.items)
      } catch (error) {
        console.log('loadAllItems', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async deleteProduct({ commit, dispatch }, id) {
      commit('setLoading', true)
      try {
        await axios.delete(`/api/cart/${id}`)
        dispatch('loadItems', {
          params: {
            page: defaultState.pages.page,
            limit: defaultState.pages.limit,
            language: defaultState.language
          }
        })
        dispatch('loadAllItems')
      } catch (error) {
        console.log('deleteProduct', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async clearCart({ commit, dispatch }) {
      commit('setLoading', true)
      try {
        await axios.delete('/api/cart')
        dispatch('loadItems', {
          params: {
            page: defaultState.pages.page,
            limit: defaultState.pages.limit,
            language: defaultState.language
          }
        })
      } catch (error) {
        console.log('clearCart', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async createOrder({ commit }, payload) {
      commit('setLoading', true)
      try {
        await axios.post('/api/order', payload)
        commit('setTotal', null)
        commit('setTotalQuantity', null)
        commit('setItems', [])
      } catch (error) {
        console.log('createOrder', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async addProduct({ commit, dispatch }, payload) {
      commit('setLoading', true)
      try {
        const response = await axios.post('/api/cart', payload)
        dispatch('loadItems', {
          params: {
            page: defaultState.pages.page,
            limit: defaultState.pages.limit,
            language: defaultState.language
          }
        })
        return response
      } catch (error) {
        console.log('addProduct', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async changeQuantity({ commit, dispatch }, payload) {
      commit('setLoading', true)
      try {
        await axios.put('/api/cart', payload)
        dispatch('loadItems', {
          params: {
            page: defaultState.pages.page,
            limit: defaultState.pages.limit,
            language: defaultState.language
          }
        })
      } catch (error) {
        console.log('changeQuantity', error)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getItems(state) {
      return state.items
    },
    getAllDeliveryTypes(state) {
      return state.allItems.map(item => item.deliveryType)
    },
    getPages(state) {
      return state.pages
    },
    getLanguage(state) {
      return state.language
    },
    getTotalCost(state) {
      return state.totalCost
    },
    getTotalNds(state) {
      return state.totalNds
    },
    getTotalQuantity(state) {
      return state.totalQuantity
    }
  }
}
