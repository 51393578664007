<template>
  <div class="user-menu">
    <router-link v-if="profileLink" :to="profileLink" v-slot="{ navigate }" custom>
      <a :href="profileLink" @click="navigate" class="user-menu__item user-menu__profile">Профиль пользователя</a>
    </router-link>
    <p class="user-menu__item" @click="logOutClickHandler">Выход</p>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'vUserMenu',
  props: ['profileLink'],
  emits: ['closeUserMenu'],
  setup(_, { emit }) {
    const store = useStore()
    const isActive = ref(false)

    const logOutClickHandler = () => {
      store.commit('auth/logout')
    }

    const keydownHandler = event => {
      if (event.key === 'Escape') {
        emit('closeUserMenu')
      }
    }

    onMounted(() => { document.addEventListener('keydown', keydownHandler) })
    onUnmounted(() => { document.removeEventListener('keydown', keydownHandler) })

    return { isActive, logOutClickHandler }
  }
}
</script>

<style lang="scss" scoped>
.user-menu {
  min-width: 162px;
  padding: 16px 20px;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 16px;

  &__item {
    font-size: 16px;
    line-height: 21px;
    &:hover {
      color: $color-red;
    }
  }

  &__profile {
    display: inline-block;
    white-space: nowrap;
    color: $color-black;
    text-decoration: none;
    margin-bottom: 8px;
  }
}
</style>
