import axios from 'axios'

const defaultState = {
  responsible: {},
  isLoading: false
}

export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setResponsible(state, responsible) {
      state.responsible = responsible
    }
  },
  actions: {
    async loadResponsible({ commit }, payload) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/info/contact')
        commit('setResponsible', data)
      } catch (error) {
        console.log('loadResponsible', error)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    getResponsible(state) {
      return state.responsible
    }
  }
}
