import axios from 'axios'

const defaultState = {
  employees: [],
  isLoading: false
}
export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setEmployees(state, employees) {
      state.employees = employees
    },
    setLoading(state, bool) {
      state.isLoading = bool
    }
  },
  actions: {
    async loadResponsibles({ commit }, clientId) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get('/api/employees-responsibles', {
          params: { clientId }
        })
        commit('setEmployees', data)
        commit('setLoading', false)
      } catch (error) {
        console.log('loadResponsibles', error)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getEmployees(state) {
      return state.employees
    },
    isLoading(state) {
      return state.isLoading
    }
  }
}
