<template>
  <ul class="tooltip">
    <li
      v-for="(status, index) in statusList"
      :key="status.name"
      class="tooltip__item"
      :class="{ 'tooltip__item_active': getIcon(index) === activeIcon }"
    >
      <img class="tooltip__icon" :src="getIcon(index)" alt="">
      <div>
        <p class="tooltip__title">{{ STATUSES[status.name] }}</p>
        <p class="tooltip__date">{{ status.date }}</p>
      </div>
      <span v-if="index !== statusList.length - 1" class="tooltip__divider"></span>
    </li>
  </ul>
</template>

<script>
import { computed, ref } from 'vue'

import { STATUSES } from '@/utils/constants'

import doneIcon from '../../assets/icons/tooltip/done.svg'
import activeIcon from '../../assets/icons/tooltip/active.svg'
import pendingIcon from '../../assets/icons/tooltip/pending.svg'

export default {
  name: 'vStatusTooltip',
  props: ['info', 'deliveryType', 'currentStatus'],
  setup(props) {
    const allExistingStatuses = ['no_data_from_onec', 'reserved', 'ready_to_ship', 'handed_over_to_the_courier_service', 'delivering', 'delivered', 'return']
    const physicalStatuses = [
      { name: 'no_data_from_onec' },
      { name: 'reserved' },
      { name: 'ready_to_ship' },
      { name: 'handed_over_to_the_courier_service' },
      { name: 'delivered' }
    ]
    const electronicStatuses = [
      { name: 'no_data_from_onec' },
      { name: 'reserved' },
      { name: 'ready_to_ship' },
      { name: 'delivering' },
      { name: 'delivered' }
    ]

    const idx = ref(allExistingStatuses.indexOf(props.currentStatus))

    const statusList = computed(() => {
      let commonStatuses = []
      if (props.deliveryType === 'physical') {
        commonStatuses = physicalStatuses
      } else {
        commonStatuses = electronicStatuses
      }
      const isReturn = props.info.find(status => status.name === 'return')
      if (isReturn) {
        commonStatuses.push(isReturn)
      }
      return commonStatuses.map(el => {
        const validExistingStatuses = allExistingStatuses.slice(0, idx.value + 1)
        const filterdStatuses = props.info.filter(status => validExistingStatuses.includes(status.name))
        const lastMatch = filterdStatuses.filter(status => status.name === el.name).at(-1)
        return lastMatch || el
      })
    })

    const getIcon = index => {
      let lastIndex = 0
      statusList.value.forEach((el, index) => {
        if (el.date) {
          lastIndex = index
        }
      })
      if (index === lastIndex) {
        return activeIcon
      } else if (index < lastIndex) {
        return doneIcon
      } else {
        return pendingIcon
      }
    }

    return { doneIcon, activeIcon, pendingIcon, STATUSES, statusList, getIcon }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  padding: 16px;
  background: $color-white;
  box-shadow: 0 4px 8px rgba(23, 28, 32, 0.2);
  border-radius: 8px;
  z-index: 100;

  &__item {
    position: relative;
    height: 33px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: 700;
    color: $color-grey;

    &:last-child {
      margin-bottom: 0;
    }

    &_active {
      color: $color-green;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__title {
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
  }

  &__date {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  &__divider {
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 25px;
    background-color: $color-border-grey;
    left: 5px;
    top: 29px;
  }
}
</style>
