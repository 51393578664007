const nameMask = {
  mask: 'A*',
  masked: true,
  tokens: {
    A: { pattern: /[A-ZА-ЯЙЁa-zа-яйё№,.<>/?;:'"[\]{} \\|`~!@#$%^&*()\-+=_]/ }
  }
}

const allSymbolsMask = {
  mask: 'a*',
  masked: true,
  tokens: {
    a: { pattern: /[0-9A-Za-zА-ЯЙЁа-яйё№,.<>/?;:'"{} [\]\\|`~!@#$%^&*()+=\-_]/ }
  }
}

const emailMask = {
  mask: 'a*',
  masked: true,
  tokens: {
    a: { pattern: /[0-9A-Za-zА-ЯЙЁа-яйё№,.<>/?;:'"{}[\]\\|`~!@#$%^&*()+=\-_]/ }
  }
}

const codeMask = {
  mask: 'ZZZ ZZZ',
  masked: true,
  tokens: {
    Z: { pattern: /[0-9]/ }
  }
}

const numberMask = {
  mask: 'Z*',
  masked: true,
  tokens: {
    Z: { pattern: /[0-9]/ }
  }
}

const domainMask = {
  mask: 'Z*',
  masked: true,
  tokens: {
    Z: { pattern: /[0-9A-Za-z-_]/ }
  }
}

const notZeroStart = {
  mask: 'ZX*',
  masked: true,
  tokens: {
    Z: { pattern: /[1-9]/ },
    X: { pattern: /[0-9]/ }
  }
}

const dateMask = {
  mask: 'ZX.YX.XX',
  masked: true,
  tokens: {
    Z: { pattern: /[0-3]/ },
    Y: { pattern: /[0-1]/ },
    X: { pattern: /[0-9]/ }
  }
}

export { nameMask, codeMask, numberMask, domainMask, notZeroStart, allSymbolsMask, dateMask, emailMask }
