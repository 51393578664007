<template>
  <div class="v-error">
    <img :src="errorIcon" alt="v-error-icon">
    <div class="v-error__block" v-if="text.includes('Не найден пользователь с email')">
      <p>Аккаунта с такой почтой нет.</p>
      <p>Вы можете написать на <a class="v-error__link" href="mailto:mail@softline.com">e-supply@softline.com</a></p>
    </div>
    <p v-else class="v-error__text">{{text}}</p>
  </div>
</template>
<script>
export default {
  name: 'vError',
  props: ['text'],
  data() {
    return {
      errorIcon: require('../../assets/icons/errorIcon.svg')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/variables";
  .v-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px 4px;
    border: 1px solid rgba(201, 13, 70, 0.15);
    box-sizing: border-box;
    border-radius: 16px;
    margin-bottom: 8px;

    &__text {
      margin: 0 0 0 8px;
      font-size: 16px;
      line-height: 21px;
      color: #A4182C;
    }

    &__block {
      margin-left: 8px;
    }

    &__link {
      color: $color-red;
    }
  }
</style>
