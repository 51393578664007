<template>
  <ul class="list">
    <li
      v-for="(item, index) in list"
      :key="item.id"
      class="item"
      :class="{ active: index === activeIndex }"
      @click="itemClickHandler(index)"
    >
      {{ item.title }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'vTabs',
  emits: ['change'],
  props: ['list', 'activeIndex'],
  methods: {
    itemClickHandler(index) {
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.list {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 36px;
  overflow: hidden;

  .item {
    font-size: 16px;
    line-height: 19px;
    color: $color-black;
    background-color: $color-white;
    padding: 7px 16px;
    border: 1px solid $color-red;
    border-right: none;
    cursor: pointer;
    user-select: none;

    &:first-child {
      padding-left: 24px;
      border-radius: 32px 0 0 32px;
    }

    &:last-child {
      padding-right: 24px;
      border-radius: 0 32px 32px 0;
      border-right: 1px solid $color-red;
    }

    &.active {
      color: $color-white;
      background-color: $color-red;
      font-weight: 700;
    }
  }
}
</style>
