<template>
  <button class="button" :class="{ [isSize]: isSize }" :type="type" :disabled="disabled">
    <done-icon v-if="isIcon" class="button__icon"></done-icon>
    {{ text }}
  </button>
</template>

<script>
import DoneIcon from '@/components/icons/done-icon.vue'

export default {
  name: 'vGreenButton',
  props: ['text', 'type', 'size', 'disabled', 'isIcon'],
  components: { DoneIcon },
  computed: {
    isSize() {
      return `button__${this.size}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.button {
  display: flex;
  align-items: center;
  font-weight: 700;
  white-space: nowrap;
  color: $color-green;
  background-color: $color-white;
  border: 1px solid $color-green;
  border-radius: 32px;
  cursor: pointer;
  user-select: none;
  fill: $color-green;

  &:hover {
    color: $color-white;
    background-color: $color-green;
    fill: $color-white;
  }

  &:active {
    box-shadow: inset 0px 4px 4px #10701F;
  }

  &:disabled {
    color: $color-grey;
    border-color: $color-grey;
    fill: $color-grey;
    pointer-events: none;
  }

  &__m {
    height: 35px;
    padding: 8px 20px;
    font-size: 16px;
    line-height: 19px;
  }

  &__l {
    height: 44px;
    padding: 10px 24px 12px;
    font-size: 18px;
    line-height: 22px;
  }

  &__icon {
    margin-bottom: 2px;
  }
}
</style>
