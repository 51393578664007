<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">
    <path d="M6.99999 4.00002V11.6667C6.99999 13.14 5.80666 14.3334 4.33332 14.3334C2.85999 14.3334 1.66666 13.14 1.66666 11.6667V3.33335C1.66666 2.41335 2.41332 1.66669 3.33332 1.66669C4.25332 1.66669 4.99999 2.41335 4.99999 3.33335V10.3334C4.99999 10.7 4.69999 11 4.33332 11C3.96666 11 3.66666 10.7 3.66666 10.3334V4.00002H2.66666V10.3334C2.66666 11.2534 3.41332 12 4.33332 12C5.25332 12 5.99999 11.2534 5.99999 10.3334V3.33335C5.99999 1.86002 4.80666 0.666687 3.33332 0.666687C1.85999 0.666687 0.666656 1.86002 0.666656 3.33335V11.6667C0.666656 13.6934 2.30666 15.3334 4.33332 15.3334C6.35999 15.3334 7.99999 13.6934 7.99999 11.6667V4.00002H6.99999Z" />
  </svg>
</template>

<script>
export default {
  name: 'AttachIcon'
}
</script>
