<template>
  <Datepicker
    :enableTimePicker="false"
    locale="ru-RU"
    position="right"
    monthNameFormat="long"
    :hideOffsetDates="true"
    :previewFormat="' '"
    @update:modelValue="setDate"
    @internalModelChange="cellClickHandler"
    @open="openCalendar"
    @closed="closeCalendar"
    ref="dp"
    :disabled="disabled"
  >
    <template #dp-input="">
      <div class="input" :class="{ disabled }">
        <v-input :placeholder="placeholder" :modelValue="date ? dateFormatter.format(date) : ''" :error="error" :className="className" :disabled="disabled" :readonly="true" />
        <CalendarIcon class="input-icon calendar-icon" :class="{ disabled }" />
        <ApproveNoIcon v-if="date" class="input-icon approve-no-icon" @click.stop="clearInput" />
      </div>
    </template>
    <template #arrow-left>
      <ArrowBackIcon class="arrow" />
    </template>
    <template #arrow-right>
      <ArrowBackIcon class="arrow arrow-right" />
    </template>
    <template #action-select></template>
  </Datepicker>
</template>

<script>
import ArrowBackIcon from '../icons/arrow-back-icon.vue'
import CalendarIcon from '../icons/calendar-icon.vue'
import ApproveNoIcon from '../icons/approve-no-icon.vue'
import Datepicker from 'vue3-date-time-picker'
import dateFormatter from '../../utils/date-formatter'

export default {
  name: 'vDatePicker',
  components: { ApproveNoIcon, ArrowBackIcon, CalendarIcon, Datepicker },
  props: ['date', 'error', 'placeholder', 'className', 'disabled', 'handleChange'],
  emits: ['update:date'],
  data: () => ({
    dateFormatter,
    isActive: false,
    value: null
  }),
  methods: {
    clearInput() {
      this.$refs.dp.clearValue()
      this.handleChange()
    },
    setDate(date) {
      this.$emit('update:date', +new Date(date))
      this.value = +new Date(date)
    },
    cellClickHandler() {
      this.$refs.dp.selectDate()
    },
    openCalendar() {
      this.isActive = true
    },
    closeCalendar() {
      this.isActive = false
    }
  },
  watch: {
    isActive() {
      if (!this.isActive) {
        this.handleChange(this.value)
      }
    }
  }
}
</script>

<style lang="scss">
// ========================================
// ↓↓↓↓↓ Инпут ↓↓↓↓↓
.picker-container {
  overflow: hidden;
}

.input {
  position: relative;
}

.input .v-input__field{
  margin-bottom: 0;
  // pointer-events: none;

  .v-input__input {
    width: 100%;
  }
}

.input-icon {
  position: absolute;
  line-height: 1;
  top: 49%;
  right: 21px;
  transform: translateY(-50%);
  fill: $color-red;

  &.disabled {
    fill: $color-grey;
  }
}

.approve-no-icon {
  width: 22px;
  display: none;
  cursor: pointer;
  margin-right: -2px;
}

.input:not(.disabled):hover .approve-no-icon {
  display: block;
}
// ↑↑↑↑↑ Инпут ↑↑↑↑↑
// ========================================
// ↓↓↓↓↓ Шапка календаря ↓↓↓↓↓
.dp__month_year_row {
  display: flex;
  align-items: center;
}

.dp__month_year_select {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  order: -1;
  pointer-events: none;

  &::first-letter {
    text-transform: uppercase;
  }

  &[aria-label="Open months overlay"] {
    padding-right: 5px;
  }

  &[aria-label="Open years overlay"] {
    margin-right: auto;
  }
}

.arrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.arrow-right {
  margin-left: 6px;
  transform: rotate(180deg);
}
// ↑↑↑↑↑ Шапка календаря ↑↑↑↑↑
// ========================================
// ↓↓↓↓↓ Окно календаря ↓↓↓↓↓
.dp__menu  {
  position: absolute;
  padding: 20px 24px;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(23, 28, 32, 0.2);
  user-select: none;
}
// ↑↑↑↑↑ Окно календаря ↑↑↑↑↑
// ========================================
// ↓↓↓↓↓ Ячейки календаря ↓↓↓↓↓
.dp__calendar_row {
  display: flex;
  justify-content: space-between;
}

.dp__calendar_item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  margin: 5.5px 3.5px;
  border-radius: 100%;
  font-size: 14px;
  line-height: 17px;
}

.dp__cell_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    color: $color-white;
    background-color: $color-red;
  }
}

.dp__cell_offset {
  pointer-events: none;
  cursor: default;
}

.dp__today {
  font-weight: 700;
  color: $color-red;

  &:hover {
    color: $color-white;
  }
}

.dp__active_date {
  color: $color-white;
  background-color: $color-red;
}
// ↑↑↑↑↑ Ячейки календаря ↑↑↑↑↑
// ========================================
// ↓↓↓↓↓ Дни недели ↓↓↓↓↓
.db__calendar_header {
  display: flex;
  justify-content: space-between;
}

.dp__calendar_header_item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  margin: 5.5px 3.5px;
  border-radius: 100%;
  color: $color-grey;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}
// ↑↑↑↑↑ Дни недели ↑↑↑↑↑
// ========================================
// ↓↓↓↓↓ Кнопки ↓↓↓↓↓
.dp__action_buttons {
  display: flex;
  justify-content: space-between;
}

.dp__action {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  background: $color-white;
  border: 1px solid $color-red;
  border-radius: 12px;
  padding: 2px 12px 4px;
  margin-top: 10px;
  cursor: pointer;
}

.dp__cancel {
  color: $color-red;
}

.dp__select {
  color: $color-white;
  background-color: $color-red;
}
// ↑↑↑↑↑ Кнопки ↑↑↑↑↑
</style>
