const baseURL = 'esupply.softline.com'

const TITLE_STATUS = {
  payment: 'Статусы оплаты',
  delivery: 'Статусы заказов'
}

const ALL_STATUS = {
  paid: 'Оплачен',
  overpaid: 'Переплачен',
  partiallyPaid: 'Частично оплачен',
  notPaid: 'Не оплачен',
  accepted: 'Принят в работу',
  awaitingPayment: 'Ожидает оплаты',
  delivered: 'Доставлен',
  completed: 'Завершён'
}

const STATUSES = {
  no_data_from_onec: 'В работе',
  working: 'В работе',
  reserved: 'Зарезервирован',
  'ready_to_ship': 'Готов к отправке',
  'handed_over_to_the_courier_service': 'Передан в курьерскую службу',
  delivering: 'Доставляется',
  delivered: 'Доставлен',
  return: 'Запрос на возврат'
}

const parseStatus = {
  paid: 'Оплачен',
  overpaid: 'Переплачен',
  partially: 'Частично оплачен',
  not_paid: 'Не оплачен',
  no_data_from_onec: 'Нет данных',
  accepted: 'Принят в работу',
  awaiting: 'Ожидает оплаты',
  delivered: 'Доставлен',
  completed: 'Завершён',
  on_agreement: 'На согласовании',
  agree: 'Согласован',
  reject: 'Отклонён'
}

export {
  TITLE_STATUS,
  ALL_STATUS,
  STATUSES,
  parseStatus,
  baseURL
}
