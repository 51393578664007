import { createStore, createLogger } from 'vuex'
import auth from './modules/auth.module'
import admins from './modules/admins.module'
import clients from './modules/clients.module'
import users from './modules/users.module'
import catalog from './modules/catalog.module'
import search from './modules/search.module'
import products from './modules/products.module'
import employees from './modules/employees.module'
import orders from './modules/orders.module'
import cart from './modules/cart.module'
import requests from './modules/requests.module'
import contacts from './modules/contacts.module'

const plugins = []
if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}

export default createStore({
  plugins,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    admins,
    clients,
    users,
    catalog,
    search,
    products,
    employees,
    cart,
    orders,
    requests,
    contacts
  }
})
