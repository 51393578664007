<template>
  <svg width="21" height="4" viewBox="0 0 21 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.00684" cy="2" r="2"/>
    <circle cx="10.0068" cy="2" r="2"/>
    <circle cx="18.0068" cy="2" r="2"/>
  </svg>
</template>

<script>
export default {
  name: 'dotsIcon'
}
</script>
