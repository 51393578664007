<template>
  <component v-if="layout" :is="layout"></component>
</template>
<script>
import UserLayout from '@/layouts/UserLayout'
import AuthLayout from '@/layouts/AuthLayout'
import AdminLayout from '@/layouts/AdminLayout'
import GeneralLayout from '@/layouts/GeneralLayout'

export default {
  components: {
    AuthLayout, UserLayout, AdminLayout, GeneralLayout
  },
  setup() {},
  computed: {
    layout() {
      return this.$route.meta.layout
    }
  }
}
</script>
<style lang="scss">

</style>
