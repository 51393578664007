<template>
  <teleport to="body">
    <div class="modal" @click="closeModal" @keydown.esc="closeModal" @keydown.enter="onSubmit" tabindex="0" ref="modal">
      <div class="content" @click.stop>
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'vModal',
  emits: ['closeModal', 'submitModal'],
  mounted() {
    document.body.style.overflow = 'hidden'
    this.$refs.modal.focus()
  },
  unmounted() {
    document.body.style.overflow = ''
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    onSubmit() {
      this.$emit('submitModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: $color-light-white, $alpha: 0.9);

  .content {
    background-color: $color-white;
    padding: 24px 32px;
    box-shadow: 0px 4px 8px rgba(23, 28, 32, 0.2);
    border-radius: 16px;
  }
}
</style>
