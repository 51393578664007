<template>
  <div class="auth-layout">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'AuthLayout'
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables";

 .auth-layout {
   width: 100vw;
   height: 100vh;
   background-color: $color-background;
 }
</style>
