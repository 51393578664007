<template>
  <button :type="type" class="button" :class="{ [isSize]: isSize }" :disabled="disabled">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'vBorderButton',
  props: ['text', 'type', 'size', 'disabled'],
  computed: {
    isSize() {
      return `button__${this.size}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.button {
  color: $color-red;
  background-color: $color-white;
  font-weight: 700;
  white-space: nowrap;
  border: 1px solid $color-red;
  border-radius: 32px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: $color-red-hover;
    border-color: $color-red-hover;
  }

  &:active {
    box-shadow: inset 0px 4px 4px rgba(176, 11, 61, 0.2);
  }

  &:disabled {
    color: $color-grey;
    border-color: $color-grey;
    pointer-events: none;
  }

  &__m {
    height: 35px;
    padding: 7px 20px;
    font-size: 16px;
    line-height: 19px;
  }

  &__l {
    height: 44px;
    padding: 10px 24px 12px;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
