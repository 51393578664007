<template>
  <li class="item">
    <div class="card" :class="{'card_blocked': card.blocked || !card.price}" @click="cardClickHandler(card)">
      <img class="card__image" :src="card.image || emptyImage" alt="">
      <div class="card__content">
        <p class="card__name">{{ card.name }}</p>
        <p class="card__delivery">Доставка: {{ card.deliveryType === 'physical' ? 'физическая' : 'ключ на E-mail' }}</p>
      </div>
      <div v-if="card.price" class="card__numbers">
        <p class="card__price">{{ currency(card.price) }} ₽/шт</p>
        <v-counter-input
          class="card__counter"
          v-model:counter.number="counter"
          :minQuantity="card.minQuantity"
          :maxQuantity="card.maxQuantity"
        />
        <p class="card__sum">{{ currency(sum) }} ₽</p>
      </div>
    </div>
    <CrossIcon class="item__cross" @click="$emit('openModal', card.productId)" />
  </li>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import emptyImage from '@/assets/images/empty.jpg'
import currency from '@/utils/currency-formatter.js'
import CrossIcon from '@/components/icons/cross-icon.vue'

export default {
  name: 'vCartItem',
  props: ['card'],
  components: { CrossIcon },
  emits: ['openModal', 'addError', 'deleteError'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const min = props.card.minQuantity || 1
    const max = props.card.maxQuantity || 999999

    const counter = ref(props.card.quantity)
    const sum = computed(() => props.card.price * counter.value)

    const cardClickHandler = card => {
      if (card.familyId) {
        router.push({ name: 'product-family', params: { productFamilyId: card.familyId } })
        return
      }
      router.push({ name: 'product', params: { productId: card.productId } })
    }

    onMounted(() => {
      if ((counter.value < min || counter.value > max) || !props.card.price) {
        emit('addError')
      }
    })

    watch(counter, async () => {
      if (counter.value < min || counter.value > max) {
        emit('addError')
        return
      }
      emit('deleteError')
      await store.dispatch('cart/changeQuantity', { productId: props.card.productId, quantity: counter.value })
    })

    return {
      counter, sum, currency, cardClickHandler, emptyImage
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;

  &__cross {
    max-width: 12px;
    min-width: 12px;
    cursor: pointer;
  }
}

.card {
  display: flex;
  width: 100%;
  min-width: 0;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 12px;
  padding: 20px;
  margin-right: 24px;
  cursor: pointer;

  &_blocked {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      background: $color-white;
    }
  }

  &__image {
    width: 100%;
    max-width: 85px;
    max-height: 85px;
    margin-right: 16px;
    object-fit: contain;
  }

  &__content {
    width: 100%;
    min-width: 0;
  }

  &__name {
    max-height: 44px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    padding-right: 30px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__numbers {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
  }

  &__price {
    width: 125px;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__counter {
    justify-content: center;
    width: 130px;
  }

  &__sum {
    width: 135px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__delivery {
    font-size: 14px;
    line-height: 17px;
    color: $color-grey;
  }
}
</style>
