export default function(num) {
  const result = new Intl.NumberFormat().format(num)

  if (Math.floor(num) === +num) {
    return result
  } else if (Math.floor(num * 10) === +num * 10) {
    return result + '0'
  }

  return result
}
