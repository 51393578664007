import axios from 'axios'
import { format } from 'date-fns'

const defaultState = {
  clients: [],
  client: {},
  pages: {
    total: 0,
    limit: 10,
    limitList: [10, 20, 30],
    page: 1
  },
  isLoading: false
}
export default {
  namespaced: true,
  state() {
    return defaultState
  },
  mutations: {
    setClients(state, clients) {
      state.clients = clients
    },
    setClient(state, client) {
      state.client = client
    },
    setLoading(state, bool) {
      state.isLoading = bool
    },
    setPages(state, pages) {
      state.pages = {
        ...state.pages,
        ...pages
      }
    }
  },
  actions: {
    async loadClients({ commit }, payload) {
      commit('setLoading', true)
      const params = {
        page: payload?.page ? parseInt(payload?.page, 10) : defaultState.pages.page,
        limit: payload?.limit ? parseInt(payload?.limit, 10) : defaultState.pages.limit
      }
      try {
        const { data } = await axios.get('/api/clients', {
          params
        })
        commit('setClients', data.items)
        commit('setPages', {
          total: parseInt(data.paging.totalElements, 10),
          page: parseInt(data.paging.current, 10),
          limit: params.limit
        })
      } catch (error) {
        console.log('loadClients', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async loadClientById({ commit }, id) {
      commit('setLoading', true)
      try {
        const { data } = await axios.get(`/api/clients/${id}`)
        commit('setClient', data)
      } catch (error) {
        console.log('loadClientById', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async createClient({ commit }, payload) {
      commit('setLoading', true)
      try {
        // logo
        const formDataLogo = new FormData()
        formDataLogo.append('entityType', 'client')
        formDataLogo.append('file', payload.logoFile)
        const logoFile = await axios.post('/api/files/upload', formDataLogo, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        // contract
        let contractFile = ''
        if (payload.contractFileId) {
          const formDataContract = new FormData()
          formDataContract.append('entityType', 'client')
          formDataContract.append('file', payload.contractFileId)
          contractFile = await axios.post('/api/files/upload', formDataContract, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
        const data = {
          ...payload,
          status: 'active',
          logoFileId: logoFile.data.uid,
          contractFileId: contractFile.data?.uid,
          limitPostpay: +payload.limitPostpay,
          limitDays: +payload.limitDays,
          startContract: format(payload.startContract, 'DD.MM.YYYY'),
          endContract: format(payload.endContract, 'DD.MM.YYYY')
        }
        delete data.logoFile
        delete data.contractFile
        return await axios.post('/api/clients', data)
      } catch (error) {
        console.log('createClient', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async editClient({ commit }, payload) {
      commit('setLoading', true)
      try {
        // logo
        let logoFile = ''
        if (payload.filesInfo.isLogoFileWasChanged) {
          const formDataLogo = new FormData()
          formDataLogo.append('entityType', 'client')
          formDataLogo.append('file', payload.logoFile)
          logoFile = await axios.post('/api/files/upload', formDataLogo, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
        // contract
        let contractFile = ''
        if (payload.contractFileId && payload.filesInfo.isContractFileWasChanged) {
          const formDataContract = new FormData()
          formDataContract.append('entityType', 'client')
          formDataContract.append('file', payload.contractFileId)
          contractFile = await axios.post('/api/files/upload', formDataContract, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }
        const data = {
          ...payload,
          logoFileId: payload.filesInfo.isLogoFileWasChanged ? logoFile.data.uid : payload.filesInfo.prevLogoId,
          contractFileId: payload.filesInfo.isContractFileWasChanged ? contractFile.data?.uid : payload.filesInfo.prevContractId,
          limitPostpay: +payload.limitPostpay,
          limitDays: +payload.limitDays,
          startContract: format(payload.startContract, 'DD.MM.YYYY'),
          endContract: format(payload.endContract, 'DD.MM.YYYY')
        }
        delete data.logoFile
        delete data.contractFile
        delete data.filesInfo
        if (data.paymentType === 'prepayment') {
          delete data.limitPostpay
          delete data.limitDays
        }
        await axios.put(`/api/clients/${payload.id}`, data)
        commit('setLoading', false)
      } catch (error) {
        console.log('editClient', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async blockClient({ commit }, id) {
      commit('setLoading', true)
      try {
        await axios.post(`/api/clients/${id}/block`)
        commit('setLoading', false)
      } catch (error) {
        console.log('blockClient', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    },
    async deleteClient({ commit }, id) {
      commit('setLoading', true)
      try {
        await axios.delete(`/api/clients/${id}`)
        commit('setLoading', false)
      } catch (error) {
        console.log('deleteClient', error)
        return error.response
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    getClients(state) {
      return state.clients
    },
    getClient(state) {
      return state.client
    },
    getPages(state) {
      return state.pages
    },
    isLoading(state) {
      return state.isLoading
    }
  }
}
