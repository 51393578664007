<template>
  <div class="v-spinner">
    <img :src="spinner" alt="spinner" class="v-spinner__svg">
    <div class="v-spinner__background"></div>
  </div>
</template>
<script>
export default {
  name: 'vSpinner',
  data() {
    return {
      spinner: require('../../assets/spinner/spinner_dualball.svg')
    }
  }
}
</script>
<style scoped lang="scss">
 .v-spinner {
   position: fixed;
   z-index: 1000;
   width: 100%;
   height: 100%;
   top: 0;
   display: flex;
   justify-content: center;
   align-items: center;

   &__background {
     position: fixed;
     z-index: 1;
     top: 0;
     //border-radius: 16px;
     width: 100%;
     height: 100%;
     background-color: $color-white;
     opacity: 0.7;
   }

   &__svg {
     z-index: 2;
     width: 100px;
     height: 100px;
   }
 }
</style>
