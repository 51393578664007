<template>
  <div class="radio-group">
    <p class="radio-group__title">{{ title }}</p>
    <div class="radio-group__buttons">
      <v-radio-button
        v-for="item in list"
        class="radio-group__button"
        :key="item.id"
        :id="item.id"
        :name="item.name"
        :value="item.value"
        :text="item.text"
        :checked="item.value === modelValue"
        :disabled="disabled"
        :error="error"
        @click="radioButtonClickHandler(item.value)"
        @keypress.space="radioButtonKeypressHandler(item.value)"
      ></v-radio-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vRadioGroup',
  props: ['title', 'list', 'modelValue', 'error', 'disabled', 'handleChange'],
  emits: ['update:modelValue'],
  methods: {
    radioButtonClickHandler(value) {
      if (!this.disabled) {
        this.$emit('update:modelValue', value)
        this.handleChange(value)
      }
    },
    radioButtonKeypressHandler(value) {
      this.$emit('update:modelValue', value)
      this.handleChange(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group {
  display: flex;

  &__title {
    margin-right: 34px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    margin-right: 24px;
  }
}
</style>
