<template>
  <div class="radio" :class="{ error }" :tabindex="disabled ? -1 : 0" @keypress.space="radioKeypressHandler">
    <input :id="id" type="radio" :name="name" :value="value" class="input" :checked="checked" :disabled="disabled" ref="radio">
    <label class="label" :class="{ disabled }" :for="id">{{ text }}</label>
  </div>
</template>

<script>
export default {
  name: 'vRadioButton',
  props: ['id', 'name', 'value', 'text', 'checked', 'disabled', 'error'],
  methods: {
    radioKeypressHandler() {
      this.$refs.radio.checked = true
    }
  }
}
</script>

<style lang="scss" scoped>
.radio:focus-visible .label::before {
  border: 2px solid $color-blue;
}

.error .label::before {
  border: 1px solid $color-red;
}

.label {
  position: relative;
  font-size: 16px;
  line-height: 21px;
  color: $color-black;
  padding-left: 25px;
  user-select: none;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 1px solid $color-border-grey;
    background-color: $color-white;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.disabled {
    color: $color-grey;
    pointer-events: none;
  }
}

.input {
  display: none;

  &:disabled + .label::before {
    background-color: $color-border-grey;
  }

  &:disabled:checked + .label::before {
    background-color: $color-white;
  }

  &:disabled:checked + .label::after {
    background-color: $color-border-grey;
  }

  &:checked + .label::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: $color-red;
    left: 5px;
    top: 6px;
  }
}
</style>
