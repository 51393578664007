import axios from 'axios'

export const uploadFile = async ({ entityType, file }) => {
  try {
    const formData = new FormData()
    formData.append('entityType', entityType)
    formData.append('file', file)
    const response = await axios.post('/api/files/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getFile = async id => {
  try {
    const response = await axios.get(`/api/files/${id}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
