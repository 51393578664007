<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_232_8662)">
      <path d="M2.00171 2L22.0017 22" stroke="#C90D46" stroke-width="3" stroke-linecap="round"/>
      <path d="M2.00171 22L22.0017 2" stroke="#C90D46" stroke-width="3" stroke-linecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_232_8662">
        <rect width="24" height="24" fill="white" transform="translate(0.00170898)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'crossIcon'
}
</script>
